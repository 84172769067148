import { HttpClient, HttpParams }       from '@angular/common/http';
import { Injectable }                   from '@angular/core';
import { Observable }                   from 'rxjs';
import { searchRequest, SearchRequest } from '../models/search.request';
import { SearchResponse }               from '../models/search.response';
import { Contact }                      from '../shipment/models/contact';

@Injectable({
    providedIn: 'root'
})
export class CountryService {

    constructor(private readonly http: HttpClient) {
    }

    list(): Observable<string[]> {
        return this.http.get('/countries') as Observable<string[]>;
    }
}
