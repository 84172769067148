<div class="container-fluid">
    <label class="custom-file-upload" *ngIf="!shipments.length">
        <i class="fas fa-upload"></i>
        <input type="file" placeholder="Upload file" (change)="fileSelected($event.target.files)">
        {{ file?.name || 'Datei Auswählen '}}
    </label>

    <button class="btn btn-outline-primary" *ngIf="shipments.length && !saving" (click)="save()">Speichern</button>
    <button class="btn btn-outline-primary" *ngIf="saving" disabled>Wird gespeichert...Bitte warten</button>
</div>

<div class="animate__animated animate__fadeIn container-fluid shipments" *ngIf="shipments.length">
    <h5>Insgesamt {{shipments.length}} Aufträge werden importiert.</h5>
    <div class="row">
        <div class="col-3">
            Datum
        </div>
        <div class="col-3">
            Empfänger
        </div>
        <div class="col-3">
            Gesamt Gewicht
        </div>
        <div class="col-3">
            Anzahl Pakete
        </div>
    </div>
    <div class="row" *ngFor="let shipment of shipments">
        <div class="col-3">
            {{shipment.senderInformation.date | date: 'dd.MM.yyyy' }}
        </div>
        <div class="col-3">
            <div class="row">
                <div class="col-12 name"> {{shipment.recipient.name1}} </div>
                <div class="col-12"> {{shipment.recipient.street}}   {{shipment.recipient.streetNumber}} </div>
                <div class="col-12"> {{shipment.recipient.city}} -  {{shipment.recipient.country}} </div>
            </div>
        </div>
        <div class="col-3">
            {{shipment.weight}}
        </div>
        <div class="col-3">
            {{ shipment.packageCount }}
        </div>
    </div>
</div>
