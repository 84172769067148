import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable }                                                        from '@angular/core';
import { Router }                                                            from '@angular/router';
import { Observable, of }                                                    from 'rxjs';
import { catchError }                                                        from 'rxjs/operators';
import { LoginResponse }                                                     from '../login/models/login.response';
import { ContextKey }                                                        from '../storage/context.key';
import { LocalStorageContext }                                               from '../storage/local-storage.context';
import { environment }                                                       from '../../environments/environment';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    constructor(private readonly router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = environment.base + req.url;
        const jwt: LoginResponse = LocalStorageContext.get(ContextKey.login);

        let headers = new HttpHeaders();
        if (!url.includes('auth') && jwt) {
            headers = headers.append('Authorization', jwt.token);
        }
        const user = LocalStorageContext.get<LoginResponse>(ContextKey.login)?.user;
        if (user) {
            headers = headers.set('username', user?.username)
        }
        req = req.clone({
            url,
            headers
        });

        return next.handle(req).pipe(
            catchError(
                (err, caught) => {
                    if (err.status === 401 && !this.router.url.includes('sign-in')) {
                        this.router.navigate(['/auth/sign-in']);
                        return of(err);
                    }
                    throw err;
                }
            )
        );
    }
}
