<ng-template #rt let-contact="result" let-t="term">
    <div class="row">
        <div class="col-12 name">
            {{contact.name1}}
            <small>
                {{ contact.contact}}
            </small>
        </div>
        <div class="col-12 street">
            {{ contact.street}} {{contact.streetNumber}}
            <br/>
            {{ contact.city}} / {{ contact.country}}
        </div>
    </div>
</ng-template>

<fieldset>
    <legend>
        <i class="fas fa-shipping-fast"></i>
        {{ title }}
    </legend>
    <small>Bitte geben Sie die {{ section }} Informationen ein.</small>

    <div class="form-row" [formGroup]="contact">
        <div class="form-group col">
            <label for="{{section}}-search">{{ section }} Suchen</label>
            <input type="text"
                   autocomplete="false"
                   id="{{section}}-search"
                   class="form-control"
                   [ngbTypeahead]="search"
                   (selectItem)="onContactSelected($event)"
                   [inputFormatter]="searchInputFormatter"
                   [resultTemplate]="rt"
                   placeholder="{{section}} aus meiner Kontakten übernehmen"
                   required>
        </div>
    </div>

    <div class="form-row" [formGroup]="information">
        <app-custom-input icon="far fa-calendar-alt" type="date" [label]="dateLabel || 'Datum *'" inputName="date" placeholder="Uhrzeit eingeben"></app-custom-input>
        <app-custom-input icon="far fa-clock" type="time" label="Von *" inputName="since" placeholder="Uhrzeit eingeben"></app-custom-input>
        <app-custom-input icon="far fa-clock" type="time" label="Bis *" inputName="until" placeholder="Uhrzeit eingeben"></app-custom-input>
    </div>

    <div class="form-row" [formGroup]="contact">
        <app-custom-input label="Name *" icon="far fa-id-badge" inputName="name1" placeholder="Name vom Kontakt"></app-custom-input>
        <app-custom-input label="Name 2" icon="far fa-clipboard" inputName="name2" placeholder="Name 2 vom Kontakt"></app-custom-input>
    </div>

    <div class="form-row" [formGroup]="contact">
        <div class="form-group col icon">
            <i class="fas fa-road"></i>
            <label for="{{section}}-street">Straße *</label>
            <app-address-autocomplete
                id="{{section}}-street"
                [contact]="contact"
                [adressType]="'geocode'">
            </app-address-autocomplete>
        </div>
        <app-custom-input label="Haus Nummer *" icon="fas fa-door-open" inputName="streetNumber" placeholder="Haus Nummer *"></app-custom-input>
    </div>

    <div class="form-row" [formGroup]="contact">
        <app-custom-input label="PLZ *" icon="fas fa-mail-bulk" inputName="zip" placeholder="PLZ eingeben"></app-custom-input>
        <app-custom-input label="Ort *" icon="fas fa-map-marker-alt" inputName="city" placeholder="Ort eingeben"></app-custom-input>

        <div class="form-group col icon">

            <i class="fas fa-globe-europe"></i>
            <label for="{{uuid}}land-input">Land *</label>
            <input id="{{uuid}}land-input"
                   placeholder="Land Eingeben"
                   type="text"
                   class="form-control"
                   formControlName="country"
                   (focus)="focus$.next($any($event).target.value)"
                   [editable]="false"
                   [ngbTypeahead]="searchCountry" appInputHasError/>
        </div>

    </div>

    <div class="form-row" [formGroup]="contact">
        <app-custom-input label="Tel" type="tel" icon="fas fa-phone-square-alt" inputName="tel" placeholder="Telefonnummer eingeben"></app-custom-input>
        <app-custom-input label="E-Mail"  type="email" icon="fas fa-envelope-open-text" inputName="email" placeholder="E-Mail Adresse eingeben"></app-custom-input>
    </div>

    <div class="form-row">
        <div class="form-group col icon" [formGroup]="contact">
            <i class="fas fa-user-check"></i>
            <label for="{{section}}-partner">Ansprech Partner</label>
            <input type="text" id="{{section}}-partner" formControlName="partner" class="form-control">
        </div>

        <div class="form-group col icon" [formGroup]="information">
            <i class="fas fa-calculator"></i>
            <label for="{{section}}-reference">Referenz</label>
            <input type="text" id="{{section}}-reference" formControlName="reference" class="form-control">
        </div>

    </div>
    <div class="form-row" [formGroup]="information">
        <div class="form-group col icon">
            <i class="fas fa-book"></i>
            <label for="{{section}}-additional-notice">{{infoLabel}}</label>
            <textarea id="{{section}}-additional-notice" formControlName="notice" class="form-control" rows="3"></textarea>
        </div>
    </div>
</fieldset>
