<div class="packages animated fadeIn" *ngIf="packageGroup.length">
    <fieldset>
        <legend>
            <i class="fas fa-truck-loading"></i>
            Paketeingaben
        </legend>
        <small>
            Bitte geben Sie die Informationen für die Pakete ein. Sie haben insgesamt <strong>{{ packageGroup.length }}</strong> Pakete ausgewählt
        </small>

        <table class="table table-striped animated fadeIn">
            <thead>
            <tr>
                <th>#</th>
                <th>Info</th>
                <th>Länge *</th>
                <th>Breite * </th>
                <th>Höhe *</th>
                <th>Gewicht *</th>
                <th>Volumengewicht</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let pack of packageGroup.controls; let i = index;" [formGroup]="pack">
                <td>
                    <b>{{ i + 1 }}.</b>
                </td>
                <td>
                    <input type="text" id="package-info-{{i}}" class="form-control" formControlName="name" placeholder="Info eingeben">
                </td>
                <td>
                    <input type="number" id="package-length-{{i}}" class="form-control" formControlName="length" appInputHasError placeholder="Länge eingeben">
                </td>
                <td>
                    <input type="number" id="package-width-{{i}}" class="form-control" formControlName="width" appInputHasError placeholder="Breite eingeben">
                </td>
                <td>
                    <input type="number" id="package-height-{{i}}" class="form-control" formControlName="height" appInputHasError placeholder="Höhe eingeben">
                </td>
                <td>
                    <input type="number" id="package-weight-{{i}}" class="form-control" formControlName="weight" appInputHasError placeholder="Gewicht eingeben">
                </td>
                <td>
                    <input type="number" id="package-valume-weight-{{i}}" class="form-control" formControlName="volumeWeight"  appInputHasError placeholder="Es wird automatisch berechnet" readonly>
                </td>
            </tr>
            </tbody>
        </table>
    </fieldset>

</div>
