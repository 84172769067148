<div class="form">
    <fieldset [formGroup]="contact">
        <legend>
            <i class="fas fa-id-card"></i>
            Kontakt Bearbeiten
        </legend>
        <small>
            Bitte geben Sie die Kontakt- Informationen ein.
            <p>Diese Kontakt können Sie dann für weitere Buchungen auswählen, um die Aufträge noch schneller zu erstellen.</p>
        </small>

        <div class="form-row">
            <app-custom-input label="Name *" icon="far fa-id-badge" inputName="name1" placeholder="Name vom Kontakt"></app-custom-input>
            <app-custom-input label="Name 2" icon="far fa-clipboard" inputName="name2" placeholder="Name 2 vom Kontakt"></app-custom-input>
        </div>

        <div class="form-row">
            <div class="form-group col icon">
                <i class="fas fa-road"></i>
                <label for="street">Straße *</label>
                <app-address-autocomplete
                    id="street"
                    [contact]="contact"
                    [adressType]="'geocode'">
                </app-address-autocomplete>
            </div>
            <app-custom-input label="Hausnummer" icon="fas fa-door-open" inputName="streetNumber" placeholder="Haus Nummer"></app-custom-input>
        </div>

        <div class="form-row">
            <app-custom-input label="PLZ *" icon="fas fa-mail-bulk" inputName="zip" placeholder="PLZ eingeben"></app-custom-input>
            <app-custom-input label="Ort *" icon="fas fa-map-marker-alt" inputName="city" placeholder="Ort eingeben"></app-custom-input>
            <app-custom-input label="Land *" icon="fas fa-globe-europe" inputName="country" placeholder="Land eingeben"></app-custom-input>
        </div>

        <div class="form-row">
            <app-custom-input label="Tel" type="tel" icon="fas fa-phone-square-alt" inputName="tel" placeholder="Telefonnummer eingeben"></app-custom-input>
            <app-custom-input label="E-Mail *"  type="email" icon="fas fa-envelope-open-text" inputName="email" placeholder="E-Mail Adresse eingeben"></app-custom-input>
            <app-custom-input label="Ansprechperson"  icon="fas fa-user-check" inputName="partner" placeholder="Ansprech Partner eingeben"></app-custom-input>
        </div>
    </fieldset>
</div>
<app-action-bar [enableDelete]="contact.value.id && (user.role.name === 'ADMIN' || user.role.name === 'ROOT')" (delete)="delete()" (save)="save()" cancel="/contacts"></app-action-bar>
