import { Component, EventEmitter, OnInit }             from '@angular/core';
import { searchRequest, SearchRequest, SortDirection } from '../../services/models/search.request';
import { ReportService }                               from '../../services/report/report.service';
import { SearchResponse }                              from '../../services/models/search.response';
import { Report }                                      from '../../services/report/models/report';
import { ShipmentService }                             from '../../services/shipment/shipment.service';
import { PdfGeneratorUtil }                            from './utils/pdf-generator.util';
import * as toastr                                     from 'toastr';

@Component({
    selector: 'app-list-report',
    templateUrl: './list-report.component.html',
    styleUrls: ['./list-report.component.scss']
})
export class ListReportComponent implements OnInit {

    size = 25;
    direction: SortDirection = SortDirection.ASC;
    field = 'date_sortable';
    request: SearchRequest;

    onSearchResponse: EventEmitter<SearchResponse<Report>> = new EventEmitter<SearchResponse<Report>>();

    constructor(private readonly reportService: ReportService,
                private readonly shipmentService: ShipmentService) {
    }

    ngOnInit(): void {
    }

    search(request: SearchRequest) {
        this.request = request;
        this.load();
    }

    async create() {
        const report = await this.reportService.create().toPromise();
        if (!report) {
            toastr.error('Es gibt keine weitere Aufträge für den Tagesabschluss');
            return;
        }
        await this.createPdf(report, undefined);
        this.load();
    }

    async createPdf(report: Report, $event?: Event) {
        $event?.preventDefault();
        $event?.stopImmediatePropagation();

        const shipments = await this.shipmentService.findByReport(report.id).toPromise();
        PdfGeneratorUtil.generate(report, shipments);
    }

    sort(field: string) {
        this.direction = this.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        this.field = field;
        this.load();
    }

    private load() {
        this.request = this.request || searchRequest;
        this.request.size = this.size;
        this.request.field = this.field;
        this.request.sort = this.direction;

        this.reportService.search(this.request).subscribe(response => {
            this.onSearchResponse.emit(response);
        });
    }
}
