<footer class="container">
    <div class="row align-items-center">
        <div class="col-2">
            <button class="btn btn-danger" *ngIf="enableDelete" (click)="onDelete()">
                Löschen
            </button>
        </div>
        <div class="col-4 text-center">
            Made with <span style="color: #e25555;">&hearts;</span> by <a href="https://nodify.at"
                                                                          target="_blank">nodify</a></div>
        <div class="col-6 text-right">
            <button class="btn btn-danger" routerLink="{{ cancel }}">
                <i class="far fa-save"></i>
                Abbrechen
            </button>

            <button class="btn btn-primary" (click)="onSave()" [class.disabled]="disabled" [disabled]="disabled">
                <i class="far fa-save"></i>
                Speichern
            </button>
        </div>
    </div>
</footer>
