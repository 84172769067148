import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import * as Rollbar                                         from 'rollbar';

const rollbarConfig: Rollbar.Configuration = {
    accessToken: '7b4b986444df483eaf23f9fce75d8a00',
    captureUncaught: true,
    captureUnhandledRejections: true,
    addErrorContext: true,
    scrubRequestBody: true
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) private rollbar: Rollbar) {
    }

    handleError(err: any): void {
        console.error(err);
        this.rollbar.error(err.originalError || err);
    }
}

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
