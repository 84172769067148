import { Injectable }                   from '@angular/core';
import { HttpClient, HttpParams }       from '@angular/common/http';
import { Observable }                   from 'rxjs';
import { Report }                       from './models/report';
import { searchRequest, SearchRequest } from '../models/search.request';
import { SearchResponse }               from '../models/search.response';

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(private readonly http: HttpClient) {
    }

    search(request: SearchRequest = searchRequest): Observable<SearchResponse<Report>> {
        let params = new HttpParams();
        for (const key of Object.keys(request)) {
            params = params.append(key, request[key]);
        }
        return this.http.get('/reports', {params}) as Observable<SearchResponse<Report>>;
    }

    create(): Observable<Report> {
        return this.http.post('/reports', {}) as Observable<Report>;
    }
}
