import { HttpClient, HttpParams }       from '@angular/common/http';
import { Injectable }                   from '@angular/core';
import { Observable }                   from 'rxjs';
import { searchRequest, SearchRequest } from '../models/search.request';
import { SearchResponse }               from '../models/search.response';
import { Shipment }                     from './models/shipment';
import { ChangeRequest } from './models/change.request';

@Injectable({
    providedIn: 'root'
})
export class ShipmentService {

    constructor(private readonly http: HttpClient) {
    }

    search(request: SearchRequest = searchRequest): Observable<SearchResponse<Shipment>> {
        let params = new HttpParams();
        for (const key of Object.keys(request)) {
            params = params.append(key, request[key]);
        }

        request.term?.trim();
        return this.http.get('/shipments/search', { params }) as Observable<SearchResponse<Shipment>>;
    }

    find(id: number): Observable<Shipment> {
        return this.http.get('/shipments/' + id) as Observable<Shipment>;
    }

    findByReport(id: number): Observable<Shipment[]> {
        return this.http.get('/shipments/reports/' + id) as Observable<Shipment[]>;
    }

    save(shipment: Shipment): Observable<Shipment> {
        delete shipment.user;
        return this.http.post('/shipments', shipment) as Observable<Shipment>;
    }

    sendChanges(changeRequest: ChangeRequest): Observable<any> {
        return this.http.post('/shipments/mailer/send-changes', changeRequest);
    }
}
