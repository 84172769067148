<ng-template #headers>
    <div class="col-3">
        Name
    </div>
    <div class="col-3">
        Kontaktperson
    </div>
    <div class="col-3">
        Adresse
    </div>
    <div class="col-1">
        Tel
    </div>
    <div class="col-2">
        E-Mail
    </div>
</ng-template>

<ng-template #body let-data="result">
    <div class="row align-items-center" *ngFor="let contact of data; let i = index;" routerLink="/contacts/{{contact.id}}">
        <div class="col-3">
            <div class="count"
                 *ngIf="countOfShipments[i]"
                 data-toggle="tooltip"
                 placement="bottom"
                 ngbTooltip="Gesamte Aufträge vom Ihren Kontakt"
                 container="body">{{ countOfShipments[i].count }}</div>
            {{contact.name1}}
            <small>{{ contact.name2}}</small>
        </div>
        <div class="col-3">
            {{contact.partner}}
        </div>
        <div class="col-3">
            <div class="row">
                <div class="col-12 name">
                    {{ contact.street}} {{ contact.streetNumber}}
                </div>
                <div class="col-12">
                    {{ contact.zip}}, {{ contact.city }} / {{ contact.country}}
                </div>
            </div>
        </div>
        <div class="col-1">
            {{ contact.tel || '-' }}        </div>
        <div class="col-2">
            {{ contact.email || '-' }}
        </div>
    </div>
</ng-template>

<app-table (search)="search($event)" [body]="body" [headers]="headers" [size]="size" [onSearchResponse]="onSearchResponse"></app-table>
