import { Component, EventEmitter, OnInit } from '@angular/core';
import { ContactService }                  from '../../services/contact/contact.service';
import { SearchRequest }                   from '../../services/models/search.request';
import { SearchResponse }                  from '../../services/models/search.response';

@Component({
    selector: 'app-list-contact',
    templateUrl: './list-contact.component.html',
    styleUrls: ['./list-contact.component.scss']
})
export class ListContactComponent implements OnInit {

    size = 25;
    onSearchResponse: EventEmitter<SearchResponse<any>> = new EventEmitter<SearchResponse<any>>();
    countOfShipments: any[] = [] as any;

    constructor(private readonly contactService: ContactService) {
    }

    ngOnInit() {
    }

    search(request: SearchRequest) {
        this.contactService.search(request).subscribe(response => {
            this.onSearchResponse.emit(response);
            const ids = response.data.map(contact => contact.id);
            this.contactService.count(ids).subscribe(counts => this.countOfShipments = counts);
        });
    }
}
