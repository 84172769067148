import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@ng-stack/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import * as toastr from 'toastr';
import { LoginResponse } from '../../login/models/login.response';
import { CustomerService } from '../../services/customer/customer.service';
import { Customer } from '../../services/customer/models/customer';
import { Role, ROLES, User, UserSettings } from '../../services/user/models/user';
import { UserService } from '../../services/user/user.service';
import { ContextKey } from '../../storage/context.key';
import { LocalStorageContext } from '../../storage/local-storage.context';

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss', '../../../forms.scss']
})
export class CreateUserComponent implements OnInit {

    roles: Role[] = [...ROLES];

    passwordVerification: FormControl<string> = this.formBuilder.control();
    user: FormGroup<User>;
    current: User = LocalStorageContext.get<LoginResponse>(ContextKey.login).user;
    entries: any[] = [];

    isRoleSame = (a: Role, b: Role) => !a || !b ? false : a.id === b.id;
    search = (searchObserver: Observable<string>): Observable<Customer[]> =>
        searchObserver.pipe(debounceTime(200), distinctUntilChanged(),
            switchMap(term => this.customerService.search({ term, field: 'name_sortable' }).pipe(map(response => response.data)))
        );
    searchInputFormatter = (result: Customer) => result ? result.name : undefined;


    constructor(private readonly userService: UserService,
                private readonly customerService: CustomerService,
                private readonly formBuilder: FormBuilder,
                private readonly activatedRoute: ActivatedRoute,
                private readonly  router: Router) {
    }


    async ngOnInit() {
        const loggedUser = LocalStorageContext.get<LoginResponse>(ContextKey.login).user;
        if (loggedUser.role.id < 4) {
            this.roles.splice(this.roles.length - 1, 1);
        }

        this.user = this.formBuilder.group<User>({
            id: [undefined],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            tel: [''],
            email: ['', [Validators.required, Validators.email]],
            username: ['', Validators.required],
            password: [''],
            role: this.formBuilder.control(undefined, Validators.required) as any,
            customer: this.formBuilder.group<Customer>({
                ...loggedUser.customer
            }),
            userSettings: this.formBuilder.group<UserSettings>({
                id: [undefined],
                sendConfirmationEmail: [false]
            })
        });

        this.activatedRoute?.params?.subscribe(params => {
            if (params && params.id) {
                this.userService.statistics(params.id).subscribe(response => {
                    this.entries = [];
                    response.forEach(item => {
                        this.entries.push({
                            name: item.recipient.name1,
                            value: item.count
                        });
                    });
                });
                this.userService.getUser(params.id).subscribe(current => {
                    this.user.patchValue(current);
                    this.user.controls.role.setValue(current.role);
                    if (loggedUser.role.id < 3 && loggedUser.id !== current.id) {
                        this.user.controls.role.disable();
                    }
                });
            }
        })
    }

    onCustomerSelected(customer: NgbTypeaheadSelectItemEvent) {
        this.user.controls.customer.patchValue(customer.item);
    }

    async delete() {
        const confirmed = confirm('Sind Sie sicher den Benutzer zu löschen?');
        if (confirmed) {
            const deleted = this.userService.delete(this.user.value.id).toPromise();
            if (deleted) {
                toastr.info('Benutzer wurde erfolgreich gelöscht!');
                return this.router.navigate(['/users/']);
            }
        }
    }

    async save() {
        if (!this.user.valid) {
            toastr.error('Bitte alle Pflicht Felder ausfüllen!');
            return;
        }
        if (this.user.controls.password.value) {
            if (this.user.controls.password.value !== this.passwordVerification.value) {
                toastr.error('Passwörter stimmen nicht überein!');
                return false;
            }
        }

        const user = this.user.value;
        if (!user.password) {
            delete user.password;
        }
        if (!user.customer.reference) {
            user.customer.reference = '123456-generated';
        }
        try {
            const saved = await this.userService.save(this.user.value).toPromise();
            toastr.info('Die Benutzerdaten wurden erfolgreich gespeichert!');
            return this.router.navigate(['/users/']);
        } catch (err) {
            if (err.status === 409) {
                toastr.error('Benutzername existiert bereits im System, bitte versuchen Sie nochmal!');
            }

            if (err.status === 401) {
                LocalStorageContext.delete(ContextKey.login);
                LocalStorageContext.delete(ContextKey.search);
                LocalStorageContext.delete(ContextKey.page);
                return this.router.navigate(['/login/']);
            }
            return false;
        }
    }

}
