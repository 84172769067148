<ng-template #headers>
    <div class="col-1 sortable" (click)="sort('pid')">
        Sendungsnr.
        <i class="fas fa-sort" *ngIf="field !== 'pid'"></i>
        <i class="fas fa-sort-up" *ngIf="direction === 'ASC' && field === 'pid'"></i>
        <i class="fas fa-sort-down" *ngIf="direction === 'DESC' && field === 'pid'"></i>
    </div>
    <div class="col-2 sortable" (click)="sort('senderInformation.sortable_date')">
        Datum
        <i class="fas fa-sort" *ngIf="field !== 'senderInformation.sortable_date'"></i>
        <i class="fas fa-sort-up" *ngIf="direction === 'ASC' && field === 'senderInformation.sortable_date'"></i>
        <i class="fas fa-sort-down" *ngIf="direction === 'DESC' && field === 'senderInformation.sortable_date'"></i>
    </div>
    <div class="col-1">
        Referenz
    </div>
    <div class="col-2 sortable" (click)="sort('sender.name1_sortable')">
        Empfänger
        <i class="fas fa-sort" *ngIf="field !== 'sender.name1_sortable'"></i>
        <i class="fas fa-sort-up" *ngIf="direction === 'ASC' && field === 'sender.name1_sortable'"></i>
        <i class="fas fa-sort-down" *ngIf="direction === 'DESC' && field === 'sender.name1_sortable'"></i>
    </div>
    <div class="col-1 sortable">
        Gesamt Gewicht
    </div>
    <div class="col-1">
        Anzahl Pakete
    </div>
    <div class="col-2 sortable" (click)="sort('user.first_name_sortable')">
        Mitarbeiter
        <i class="fas fa-sort" *ngIf="field !== 'user.first_name_sortable'"></i>
        <i class="fas fa-sort-up" *ngIf="direction === 'ASC' && field === 'user.first_name_sortable'"></i>
        <i class="fas fa-sort-down" *ngIf="direction === 'DESC' && field === 'user.first_name_sortable'"></i>
    </div>
    <div class="col-2">
        Aktionen
    </div>
</ng-template>


<ng-template #body let-data="result">
    <div class="row align-items-center" *ngFor="let shipment of data" (click)="activate(shipment)">
        <div class="col-1">
            IS{{shipment.id.toString().padStart(8, '0')}}
            <small>
                {{shipment.status ? shipment.status.name : '-' }}
            </small>
        </div>
        <div class="col-2">
            {{shipment.senderInformation.date | date: 'dd.MM.yyyy' }}
        </div>
        <div class="col-1">
            {{shipment.recipientInformation.reference || '-'}}
        </div>
        <div class="col-2">
            <div class="row">
                <div class="col-12 name"> {{shipment.recipient.name1}} </div>
                <div class="col-12"> {{shipment.recipient.street}}   {{shipment.recipient.streetNumber}} </div>
                <div class="col-12"> {{shipment.recipient.city}} -  {{shipment.recipient.country}} </div>
            </div>
        </div>
        <div class="col-1">
            {{shipment.weight}} kg
        </div>
        <div class="col-1">
            {{shipment.packageCount}}
        </div>
        <div class="col-2">
            {{shipment.user.firstName}} {{shipment.user.lastName}}
        </div>
        <div class="col-2">
            <div class="print" (click)="createPdf(shipment, $event)">
                <i class="fas fa-print"></i>
            </div>
            <div class="print edit" (click)="activate(shipment)">
                <i class="far fa-edit"></i>
            </div>
        </div>
    </div>
</ng-template>
<app-table (search)="search($event)" [headers]="headers" [body]="body" [onSearchResponse]="onSearchResponse" [size]="size"></app-table>
