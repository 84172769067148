import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit {

    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    @Output() delete: EventEmitter<any> = new EventEmitter<any>();
    @Input() disabled: boolean;
    @Input() enableDelete: boolean;
    @Input() cancel: string;

    constructor() {
    }

    ngOnInit() {
    }

    onDelete() {
        this.delete.emit();
        return false;
    }

    onSave() {
        this.save.emit();
        return false;
    }


}
