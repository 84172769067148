import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl }                                                 from '@ng-stack/forms';
import { debounceTime, distinctUntilChanged }                          from 'rxjs/operators';
import { SearchRequest }                                               from '../../services/models/search.request';
import { SearchResponse }                                              from '../../services/models/search.response';
import { LocalStorageContext }                                         from '../../storage/local-storage.context';
import { ContextKey }                                                  from '../../storage/context.key';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

    searchResponse: SearchResponse<any> = {total: 0} as SearchResponse<any>;
    page = 0;
    pages = 0;
    searchForm: FormControl = new FormControl();

    @Input() size = 20;
    @Input() enableRoute = true;
    @Input() onSearchResponse: EventEmitter<SearchResponse<any>> = new EventEmitter<SearchResponse<any>>();
    @Input() headers: TemplateRef<any>;
    @Input() body: TemplateRef<any>;
    @Input() emptyText: string;

    @Output() search: EventEmitter<SearchRequest> = new EventEmitter<SearchRequest>();
    @Output() creating: EventEmitter<SearchRequest> = new EventEmitter<SearchRequest>();

    constructor() {
    }

    ngOnInit() {
        this.searchForm.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(() => {
            this.page = 0;
            this.search.emit({term: this.searchForm.value, size: this.size, page: this.page});
            LocalStorageContext.save(ContextKey.search, this.searchForm.value);
        });

        const term = LocalStorageContext.get<string>(ContextKey.search) || '';
        this.page = LocalStorageContext.get<number>(ContextKey.page) || 0;
        this.searchForm.setValue(term, {emitEvent: false});
        this.search.emit({term, size: this.size, page: this.page});

        this.onSearchResponse.subscribe((searchResponse: SearchResponse<any>) => {
            this.searchResponse = searchResponse;
            this.pages = Math.ceil(searchResponse.total / this.size);
        });
    }

    next(event: Event) {
        event.preventDefault();
        this.page++;
        this.emitSearch();
    }

    back(event: Event) {
        event.preventDefault();
        this.page--;
        this.emitSearch();
    }

    onPageSelect(page: number) {
        this.page = page;
        this.emitSearch();
        return false;
    }

    create() {
        this.creating.emit();
    }

    get max(): number {
        return Math.min(this.page * 25 + 25, this.searchResponse.total,);
    }

    private emitSearch() {
        LocalStorageContext.save(ContextKey.page, this.page);
        this.search.emit({term: this.searchForm.value ?? '', size: this.size, page: this.page});
    }
}
