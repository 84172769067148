import { Shipment } from '../../../services/shipment/models/shipment';
import * as jsPDF from 'jspdf';
import { OpenSansBold, OpenSansRegular } from './fonts';

const is11 = () => {
    return !!window.MSInputMethodContext && !!(document as any).documentMode;
};

export class PdfGeneratorUtil {

    static async generate(shipment: Shipment) {
        const pdf = new jsPDF.jsPDF();
        pdf.addFileToVFS('OpenSans-Regular-normal.ttf', OpenSansRegular);
        pdf.addFont('OpenSans-Regular-normal.ttf', 'OpenSans-Regular', 'normal');

        pdf.addFileToVFS('OpenSans-Bold.ttf', OpenSansBold);
        pdf.addFont('OpenSans-Bold.ttf', 'OpenSans-Bold', 'bold');


        pdf.setFont('OpenSans-Regular');

        let page = pdf;
        let index = 0;
        for (const pack of shipment.packages) {
            index++;
            page.rect(10, 8, 190, 120);
            page.addImage({
                imageData: document.getElementById('logo') as HTMLImageElement,
                x: 15,
                y: 13,
                width: 55,
                height: 10
            });

            page.setFont('OpenSans-Regular', 'normal').setFontSize(10).text('IS' + shipment.id.toString().padStart(8, '0'), 170, 25);
            page.line(10, 25, 200, 26);

            page.setFontSize(8).setFont('OpenSans-Bold', 'bold').text('Absender', 15, 33);
            page.setFont('OpenSans-Regular', 'normal').setFontSize(7)
                .text(shipment.sender.name1 || '', 15, 40)
                .text(shipment.sender.name2 || '', 15, 45)
                .text(shipment.sender.partner || '', 15, 52)
                .text(shipment.sender.street || '' + ' ' + shipment.sender.streetNumber || '', 15, 57)
                .text(`${shipment.sender.country} - ${shipment.sender.zip} ${shipment.sender.city}`, 15, 62)
                .text(shipment.sender.tel || '', 15, 70)
                .text(shipment.sender.email || '', 15, 75)
                .text(shipment.senderInformation.reference || '', 15, 83)
                .setFontSize(8).text(shipment.senderInformation.notice || '', 15, 88);

            page.setFontSize(8).setFont('OpenSans-Bold', 'bold').text('Empfänger', 125, 33);
            page.setFont('OpenSans-Regular', 'normal').setFontSize(7)
                .text(shipment.recipient.name1 || '', 125, 40)
                .text(shipment.recipient.name2 || '', 125, 45)
                .text(shipment.recipient.partner || '', 125, 52)
                .text(shipment.recipient.street + ' ' + shipment.recipient.streetNumber, 125, 57)
                .text(`${shipment.recipient.country} - ${shipment.recipient.zip} ${shipment.recipient.city}`, 125, 62)
                .text(shipment.recipient.tel || '', 125, 70)
                .text(shipment.recipient.email || '', 125, 75)
                .text(shipment.recipientInformation.reference || '', 125, 83)
                .setFontSize(8).text(shipment.recipientInformation.notice || '', 125, 88);


            page.setDrawColor('#272727');
            page.rect(15, 95, 180, 30);
            page.setFontSize(8)
                .setFont('OpenSans-Bold', 'bold')
                .text(`${shipment.senderInformation.date} ${shipment.senderInformation.since} - ${shipment.senderInformation.until}`,
                    17,
                    100);
            page.setFont('OpenSans-Regular', 'normal').setFontSize(8)
                .text(shipment.product?.name, 17, 105)
                .text(`Gesamt: Pakete: ${shipment.packageCount}, Gewicht: ${shipment.weight} kg`, 17, 110)
                .text(`Paket ${index} von ${shipment.packageCount} : ${pack.width}x${pack.height}x${pack.length} Gewicht: ${pack.weight} kg`, 17, 115);

            page.setFontSize(8)
                .setFont('OpenSans-Bold', 'bold')
                .text(`${shipment.recipientInformation.date} ${shipment.recipientInformation.since} - ${shipment.recipientInformation.until}`,
                    127,
                    100);
            page.setFont('OpenSans-Regular', 'normal');

            page.rect(185, 117, 10, 8);
            page.rect(175, 117, 10, 8);

            page.rect(185, 109, 10, 8);
            page.rect(175, 109, 10, 8);

            page.text('D', 180, 114.5, { align: 'center' });
            page.text('V', 190, 114.5, { align: 'center' });


            if (shipment.isDocument) {
                page.text('X', 180, 122.5, { align: 'center' });
            }
            if (shipment.hasInsurance) {
                page.text('X', 190, 122.5, { align: 'center' });
            }

            page = pdf.addPage();
        }

        pdf.deletePage(pdf.getNumberOfPages());

        if (is11()) {
            return pdf.output('pdfobjectnewwindow');
        }

        return pdf.output('pdfobjectnewwindow') as any as string;

    }
}
