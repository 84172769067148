import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2, SkipSelf } from '@angular/core';
import { ControlContainer }                                                       from '@angular/forms';

@Component({
    selector:      'app-custom-input',
    templateUrl:   './custom-input.component.html',
    styleUrls:     ['./custom-input.component.scss'],
    viewProviders: [
        {
            provide:    ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps:       [[new SkipSelf(), ControlContainer]]
        }
    ]
})
export class CustomInputComponent implements OnInit {
    @HostBinding('class') class = 'form-group col';

    uuid = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16)

    @Input() icon: string;
    @Input() label: string;
    @Input() inputName: string;
    @Input() placeholder: string;
    @Input() type: string;
    @Input() readonly: boolean;

    constructor(private renderer: Renderer2, private readonly elementRef: ElementRef) {
    }

    ngOnInit() {
        if (this.icon) {
            this.renderer.addClass(this.elementRef.nativeElement, 'icon');
        }
    }


}
