import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, Validators } from '@ng-stack/forms';
import * as toastr from 'toastr';
import { CustomerService } from '../../services/customer/customer.service';
import { Product } from '../../services/product/models/product';
import { ProductService } from '../../services/product/product.service';
import { Shipment, ShipmentPackage } from '../../services/shipment/models/shipment';
import { ShipmentService } from '../../services/shipment/shipment.service';
import { ShipmentForm } from './utils/shipment.form';
import { ContactService } from '../../services/contact/contact.service';
import { LocalStorageContext } from '../../storage/local-storage.context';
import { LoginResponse } from '../../login/models/login.response';
import { ContextKey } from '../../storage/context.key';
import { PdfGeneratorUtil } from '../list-booking/utils/pdf-generator.util';
import { ShipmentContact } from '../../services/shipment/models/shipment-contact';
import { UserService } from '../../services/user/user.service';
import * as moment from 'moment';
import { ChangeDetectorService } from './change-detector.service';
import { RollbarService } from '../../services/rollbar.service';
import * as Rollbar from 'rollbar';

@Component({
    selector: 'app-home',
    templateUrl: './create-booking.component.html',
    styleUrls: ['./create-booking.component.scss']
})
export class CreateBookingComponent implements OnInit {

    private existing: Shipment;

    loading = false;
    shipment = new ShipmentForm(this.formBuilder).formGroup;
    @Input() products: Product[] = [];
    isProductSame = (a: Product, b: Product) => !a || !b ? false : a.id === b.id;

    constructor(private readonly shipmentService: ShipmentService,
                private readonly customerService: CustomerService,
                private readonly userService: UserService,
                private readonly contactService: ContactService,
                private readonly productService: ProductService,
                private readonly formBuilder: FormBuilder,
                private readonly route: ActivatedRoute,
                private readonly router: Router,
                @Inject(RollbarService) private rollbar: Rollbar) {
    }

    async ngOnInit() {
        this.loading = true;
        this.shipment.get('customer').controls.name.disable();
        this.customerService.getCustomer().subscribe(value => this.shipment.get<'customer'>('customer').patchValue(value));
        this.productService.products().subscribe(value => this.products = value);

        this.shipment.get('packageCount').valueChanges.subscribe(_ => this.changedCount());

        let user = LocalStorageContext.get<LoginResponse>(ContextKey.login)?.user;
        this.rollbar.configure({
            payload: {
                person: {
                    id: user.id, // required
                    username: user.username,
                    email: user.email
                }
            }
        })

        const { params } = this.route.snapshot;
        if (params.id) {
            this.existing = await this.shipmentService.find(params.id).toPromise();

            if (!this.existing.sender.sourceContact) {
                delete this.existing.sender.sourceContact;
            }

            if (!this.existing.recipient.sourceContact) {
                delete this.existing.recipient.sourceContact;
            }
            this.shipment.patchValue(this.existing);
            this.shipment.controls.packages.patchValue(this.existing.packages);
        } else {
            user = await this.userService.getUser(user.id).toPromise();
            const customer = await this.customerService.findCustomer(user.customer.id).toPromise();
            const contact: ShipmentContact = {
                ...customer,
                sourceContact: {} as any
            };
            delete contact.id;
            this.shipment.controls.sender.patchValue(contact);
            this.shipment.controls.sender.controls.tel.patchValue(user.tel);
            this.shipment.controls.sender.controls.email.patchValue(user.email);
            this.shipment.controls.sender.controls.partner.patchValue(user.firstName + ' ' + user.lastName);
        }
        this.loading = false;
        this.shipment.controls.hasInsurance.valueChanges.subscribe(_ => {
            this.shipment.controls.worth.setValidators(this.shipment.controls.hasInsurance.value ? Validators.required : null);
            this.shipment.controls.worth.updateValueAndValidity({ onlySelf: true });
        });
        this.shipment.controls.packages.valueChanges.subscribe(_ => {
            for (const pack of this.shipment.controls.packages.controls) {
                const l = pack.controls.length.value;
                const h = pack.controls.height.value;
                const w = pack.controls.width.value;

                if (!l || !h || !w) {
                    return;
                }
                pack.controls.volumeWeight.setValue(l * h * w / 5000, { onlySelf: true });
            }
        });
    }

    changedCount() {
        const count = this.shipment.get('packageCount').value;
        if (!count) {
            return this.shipment.setControl('packages', this.formBuilder.array<ShipmentPackage>([]));
        }

        const packageCount = this.shipment.get('packages').length;
        if (packageCount === count) {
            return;
        }

        const packages = this.shipment.get('packages');
        if (count < packages.value.length) {
            const news: FormArray<ShipmentPackage> = this.formBuilder.array<ShipmentPackage>([]);
            for (let i = 0; i < count; i++) {
                news.push(packages.controls[i]);
            }
            return this.shipment.setControl('packages', news);
        }

        const length = count - packages.controls.length;
        for (let i = 0; i < length; i++) {
            packages.push(this.formBuilder.group<ShipmentPackage>({
                id: undefined,
                name: [''],
                width: [1, Validators.required],
                length: [1, Validators.required],
                height: [1, Validators.required],
                weight: [1, Validators.required],
                volumeWeight: [1 / 5000, Validators.required]
            }));
        }
    }

    async save() {
        if (!this.shipment.controls.recipient.controls.sourceContact?.controls.id.value) {
            this.shipment.controls.recipient.removeControl('sourceContact');
        }
        if (!this.shipment.controls.sender.controls.sourceContact?.controls.id.value) {
            this.shipment.controls.sender.removeControl('sourceContact');
        }

        if (this.shipment.valid) {
            const sendDate = this.shipment.controls.senderInformation.value.date;
            const recipientDate = this.shipment.controls.recipientInformation.value.date;

            if (moment(recipientDate).isBefore(moment(sendDate))) {
                toastr.error('Zustell Datum soll nicht in Vergangenheit liegen!');
                return;
            }

            this.loading = true;
            if (!this.shipment.controls.packageCount.value) {
                this.loading = false;
                return toastr.error('Mindestens ein Paket muss eingegeben werden!');
            }

            const shipment = this.shipment.getRawValue();

            if (shipment.senderInformation.since > shipment.senderInformation.until) {
                this.loading = false;
                toastr.error('Absender Datum Bis darf nicht kleiner als Von sein!');
                return;
            }

            if (shipment.recipientInformation.since > shipment.recipientInformation.until) {
                this.loading = false;
                toastr.error('Empfänger Datum Bis darf nicht kleiner als Von sein!');
                return;
            }

            if (shipment.weight <= 0 || shipment.packages.some(pack => pack.weight <= 0)) {
                this.loading = false;
                toastr.error('Gewicht darf nicht 0 oder kleiner als 0 sein!');
                return;
            }

            shipment.senderInformation.since += shipment.senderInformation.since.split(':').length === 2 ? ':00' : '';
            shipment.senderInformation.until += shipment.senderInformation.until.split(':').length === 2 ? ':00' : '';
            shipment.recipientInformation.since += shipment.recipientInformation.since.split(':').length === 2 ? ':00' : '';
            shipment.recipientInformation.until += shipment.recipientInformation.until.split(':').length === 2 ? ':00' : '';
            shipment.weight = parseFloat(shipment.weight.toString());

            try {

                if (this.existing) {
                    try {
                        const changes = ChangeDetectorService.detect(this.existing, shipment);
                        await this.shipmentService.sendChanges(changes).toPromise();
                    } catch (e) {
                        console.error(e);
                    }
                }

                const saved = await this.shipmentService.save(shipment).toPromise();
                this.shipment.patchValue(saved);
                toastr.info('Auftrag wurde erfolgreich gespeichert!');
                await PdfGeneratorUtil.generate(saved);
                this.loading = false;
                return this.router.navigate(['/bookings']);
            } catch (error) {
                toastr.error('Auftrag kann derzeit nicht gespeichert werden!');
                console.log(error);
            }
            this.loading = false;
        } else {
            this.shipment.markAllAsTouched();
            toastr.error('Bitte füllen Sie alle Pflichtfelder korrekt aus!');
        }
    }

    switch() {
        const sender = this.shipment.controls.sender.value;
        const senderInformation = this.shipment.controls.senderInformation.value;

        const recipient = this.shipment.controls.recipient.value;
        const recipientInformation = this.shipment.controls.recipientInformation.value;

        delete senderInformation.id;
        delete recipientInformation.id;
        this.shipment.controls.sender.patchValue(recipient);
        this.shipment.controls.senderInformation.patchValue(recipientInformation);

        this.shipment.controls.recipient.patchValue(sender);
        this.shipment.controls.recipientInformation.patchValue(senderInformation);
    }
}
