import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CreateBookingComponent } from './booking/create-booking/create-booking.component';
import { ListBookingComponent } from './booking/list-booking/list-booking.component';
import { BookingComponent } from './booking/booking.component';
import { ContactComponent } from './contact/contact.component';
import { CreateContactComponent } from './contact/create-contact/create-contact.component';
import { ListContactComponent } from './contact/list-contact/list-contact.component';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { ListUserComponent } from './user/list-user/list-user.component';
import { CreateUserComponent } from './user/create-user/create-user.component';
import { CustomerComponent } from './customer/customer.component';
import { CreateCustomerComponent } from './customer/create-customer/create-customer.component';
import { ListCustomerComponent } from './customer/list-customer/list-customer.component';
import { ReportComponent } from './report/report.component';
import { ListReportComponent } from './report/list-report/list-report.component';
import { ImporterComponent } from './importer/importer.component';


const routes: Routes = [
    { path: '', redirectTo: 'bookings/', pathMatch: 'full' },
    {
        component: BookingComponent,
        path: 'bookings',
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ListBookingComponent },
            { path: 'create', component: CreateBookingComponent },
            { path: ':id', component: CreateBookingComponent }
        ]
    },
    {
        component: ContactComponent,
        path: 'contacts',
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ListContactComponent },
            { path: 'create', component: CreateContactComponent },
            { path: ':id', component: CreateContactComponent }
        ]
    },
    {
        component: ReportComponent,
        path: 'reports',
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ListReportComponent },
        ]
    },
    {
        component: UserComponent,
        path: 'users',
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ListUserComponent },
            { path: 'create', component: CreateUserComponent },
            { path: ':id', component: CreateUserComponent }
        ]
    },
    {
        component: ImporterComponent,
        path: 'import',
        canActivate: [AuthGuard]
    },
    {
        component: CustomerComponent,
        path: 'customers',
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ListCustomerComponent },
            { path: 'create', component: CreateCustomerComponent },
            { path: ':id', component: CreateCustomerComponent }
        ]
    },
    { component: LoginComponent, path: 'auth/sign-in' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
