import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup }                                                           from '@ng-stack/forms';
import { Contact }                                                                          from '../../services/shipment/models/contact';

declare let google;

@Component({
    selector: 'app-address-autocomplete',
    template: `
        <input class="form-control"
               type="text"
               autocomplete="false"
               appInputHasError
               placeholder="Adresse eingeben"
               [formControl]="contact.controls.street"
               #addresstext>`,
    styleUrls: ['../../../forms.scss']
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
    @Input() adressType: string;
    @ViewChild('addresstext') addresstext: any;

    @Input() contact: FormGroup<Contact>;

    constructor() { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }

    private getPlaceAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, { types: [this.adressType]});
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            const component = place.address_components.find(current => current.types.includes('route'));
            if (component) {
                this.contact.get('street').setValue(this.findComponent(place, 'route'));
                this.contact.get('streetNumber').setValue(this.findComponent(place, 'street_number'));
                this.contact.get('zip').setValue(this.findComponent(place, 'postal_code'));
                this.contact.get('city').setValue(this.findComponent(place, 'locality'));
                this.contact.get('country').setValue(this.findComponent(place, 'country'));
                this.contact.get('country').setValue(this.findComponent(place, 'country'));
            }
        });
    }

    private findComponent(place: any, type: string): string {
        const component = place.address_components.find(current => current.types.includes(type));
        return component ? component.long_name : undefined;
    }

}
