import { Injectable }                   from '@angular/core';
import { HttpClient, HttpParams }       from '@angular/common/http';
import { Observable }                   from 'rxjs';
import { Customer }                     from './models/customer';
import { searchRequest, SearchRequest } from '../models/search.request';
import { SearchResponse }               from '../models/search.response';
import { Shipment }                     from '../shipment/models/shipment';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private readonly http: HttpClient) {
    }

    search(request: SearchRequest = searchRequest): Observable<SearchResponse<Customer>> {
        let params = new HttpParams();
        for (const key of Object.keys(request)) {
            params = params.append(key, request[key]);
        }
        return this.http.get('/customers', { params }) as Observable<SearchResponse<Customer>>;
    }

    getCustomer(): Observable<Customer> {
        return this.http.get('/customers/by-user') as Observable<Customer>;
    }

    findCustomer(id: number): Observable<Customer> {
        return this.http.get('/customers/' + id) as Observable<Customer>;
    }

    statistics(id: number): Observable<any[]> {
        return this.http.get('/customers/' + id + '/statistics') as Observable<any[]>;
    }

    save(customer: Customer): Observable<Customer> {
        return this.http.post('/customers', customer) as Observable<Customer>;
    }
}
