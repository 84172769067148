import { Customer } from '../../customer/models/customer';


export class User {
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    tel?: string;
    username: string;
    password: string;
    role: Role;
    customer?: Customer;
    userSettings?: UserSettings;
}

export interface UserSettings {
    id?: number;
    sendConfirmationEmail?: boolean;
}

export class Role {
    id: number;
    name: string;
    description: string;
}

export const ROLES: Role[] = [
    { id: 2, name: 'CLIENT', description: 'Mitarbeiter' },
    { id: 3, name: 'ADMIN', description: 'Firmen Admin' },
    { id: 4, name: 'ROOT', description: 'System Admin' },
]
