import { Injectable }      from '@angular/core';
import { HttpClient }      from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product }    from './models/product';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(private readonly http: HttpClient) {
    }

    products(): Observable<Product[]> {
        return this.http.get('/products') as Observable<Product[]>;
    }
}
