import { Shipment } from '../../../services/shipment/models/shipment';
import * as jsPDF   from 'jspdf';
import 'jspdf-autotable';
import { Report }   from '../../../services/report/models/report';
import * as moment  from 'moment';

export class PdfGeneratorUtil {

    static generate(report: Report, shipments: Shipment[]) {
        const pdf = new jsPDF.jsPDF();

        const values: any[] = [];
        let totalPackages = 0;
        shipments.forEach(shipment => {
            values.push([
                'IS' + shipment.id.toString().padStart(8, '0') + '\n' + shipment.product.name,
                shipment.recipient.name1 + shipment.recipient.name2 + '\n' +
                (shipment.recipient.partner ? shipment.recipient.partner + '\n' : '') +
                shipment.recipient.street + ' ' + shipment.recipient.streetNumber + '\n' +
                shipment.recipient.country + ' - ' + shipment.recipient.zip + ' ' + shipment.recipient.city
                ,
                shipment.recipientInformation.reference,
                shipment.weight
            ]);
            totalPackages += shipment.packages?.length || 0;
        });

        (pdf as any).autoTable({
            margin: {top: 30, left: 10, right: 5, bottom: 30},
            head: [['Sendungsnr.', 'Empf.', 'Ref.', 'Gew.']],
            body:
            values
        });

        for (let i = 1; i <= pdf.getNumberOfPages(); i++) {
            const page = pdf.setPage(i);

            page.addImage({
                imageData: document.getElementById('logo') as HTMLImageElement,
                x: 10,
                y: 10,
                width: 55,
                height: 10
            });

            page.rect(140, 11, 65, 10);
            page.setFontSize(11).setFont(undefined, 'bold').text('Tagesabschluss - ' + moment(report.date).format('DD.MM.YYYY'), 146, 17.5);
            page.setFontSize(10).setFont(undefined, 'normal').text(`Ingesamt ${totalPackages} Pakete an diesem Tag.`, 140, 25);


            page.setFontSize(9).setFont(undefined, 'normal').text('Seite ' + i + ' von ' + page.getNumberOfPages(), 90, 294);
            page.setLineWidth(0.5);
            page.line(5, 294, 65, 294);
        }
        return pdf.output('pdfobjectnewwindow') as any as string;
    }
}
