import { Component, Input, OnInit }                                     from '@angular/core';
import { FormGroup }                                                    from '@ng-stack/forms';
import { merge, Observable, Subject }                                   from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeAll, switchMap } from 'rxjs/operators';
import { ContactService }                                               from '../../../services/contact/contact.service';
import { Contact }                                                      from '../../../services/shipment/models/contact';
import { ShipmentInformation }                                          from '../../../services/shipment/models/shipment-information';
import { ShipmentContact }                                              from '../../../services/shipment/models/shipment-contact';
import { CountryService }                                               from '../../../services/country/country.service';

@Component({
    selector: 'app-booking-contact',
    templateUrl: './booking-contact.component.html',
    styleUrls: ['./booking-contact.component.scss']
})
export class BookingContactComponent implements OnInit {
    uuid = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);


    @Input() section: string;
    @Input() infoLabel: string;
    @Input() title: string;
    @Input() dateLabel: string;

    @Input() contact: FormGroup<Contact>;
    @Input() information: FormGroup<ShipmentInformation>;

    focus$ = new Subject<string>();
    countries: string[] = [];

    searchCountry = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.countries : this.countries.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10)));
    };

    constructor(private readonly contactService: ContactService,
                private readonly countryService: CountryService) {
    }

    async ngOnInit() {
        this.countries = await this.countryService.list().toPromise();
    }

    search = (searchObserver: Observable<string>): Observable<Contact[]> =>
        searchObserver.pipe(debounceTime(200),
            distinctUntilChanged(),
            switchMap(term => this.contactService.search({term}).pipe(map(response => response.data)))
        );

    searchInputFormatter = (result: Contact) => result ? result.name1 : undefined;

    onContactSelected({item}: { item: Contact }) {
        const shipmentContact: ShipmentContact = {
            ...item,
            sourceContact: item
        };
        delete shipmentContact.id;
        this.contact.patchValue(shipmentContact);
    }

}
