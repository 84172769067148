import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@ng-stack/forms';
import {Customer} from '../../../services/customer/models/customer';
import {Product} from '../../../services/product/models/product';
import {Contact} from '../../../services/shipment/models/contact';
import {Shipment, ShipmentPackage} from '../../../services/shipment/models/shipment';
import {ShipmentInformation} from '../../../services/shipment/models/shipment-information';
import {ShipmentContact} from '../../../services/shipment/models/shipment-contact';
import * as moment from 'moment';

// @ts-ignore
const required = <T>(value?: T): FormControl<T> => new FormControl<T>(value, Validators.required);
// @ts-ignore
const optional = <T>(value?: T): FormControl<T> => new FormControl<T>(value);

export class ShipmentForm {

    readonly formGroup: FormGroup<Shipment>;

    constructor(private readonly formBuilder: FormBuilder, shipment?: Shipment) {
        this.formGroup = this.formBuilder.group<Shipment>({
            id: optional(shipment?.id) as any,
            customer: this.formBuilder.group<Customer>({
                id: optional(shipment?.customer?.id),
                name: optional(shipment?.customer?.name || ''),
                reference: optional(shipment?.customer?.reference),
                name1: required(shipment?.customer?.name1),
                name2: optional(shipment?.customer?.name2),
                partner: optional(shipment?.customer?.partner),
                street: required(shipment?.customer?.street),
                streetNumber: optional(shipment?.customer?.streetNumber),
                country: required(shipment?.customer?.country),
                zip: required(shipment?.customer?.zip),
                city: required(shipment?.customer?.city),
                tel: optional(shipment?.customer?.tel),
                email: optional(shipment?.customer?.email),
            }),
            user: this.formBuilder.group({id: optional()}),
            product: this.formBuilder.control<Product>({id: 1, name: 'Overnight'}, Validators.required),
            sender: ShipmentForm.createContactGroup(this.formBuilder, shipment?.sender),
            senderInformation: this.createInformationGroup(shipment?.recipientInformation, 0),

            recipient: ShipmentForm.createContactGroup(this.formBuilder, shipment?.sender),
            recipientInformation: this.createInformationGroup(shipment?.recipientInformation, moment().days() === 5 ? 3 : 1),

            isDocument: [false],
            hasInsurance: [false],
            isCod: [false],
            worth: optional(),
            packageCount: required(1),
            weight: optional(shipment?.weight || 1),
            info: optional(),
            timeStampCreated: optional(shipment?.timeStampCreated),
            packages: this.createPackageGroup(shipment?.packages),
            status: formBuilder.group({
                id: optional(shipment?.status?.id || 1),
                name: optional(shipment?.status?.name || 'Neu'),
            })
        });
    }

    static createContactGroup(formBuilder: FormBuilder, contact: ShipmentContact): FormGroup<ShipmentContact> {
        return formBuilder.group({
            id: optional(contact?.id),
            customer: formBuilder.group({
                id: optional(contact?.customer?.id)
            }) as unknown as FormGroup<Customer>,
            name1: required(contact?.name1),
            name2: optional(contact?.name2),
            partner: optional(contact?.partner),
            street: required(contact?.street),
            streetNumber: required(contact?.streetNumber),
            country: required(contact?.country),
            zip: required(contact?.zip),
            city: required(contact?.city),
            tel: optional(contact?.tel),
            email: optional(contact?.email),
            sourceContact: this.createSourceContact(formBuilder, contact?.sourceContact)
        });
    }

    static createSourceContact(formBuilder: FormBuilder, contact: Contact): FormGroup<Contact> {
        return formBuilder.group({
            id: optional(contact?.id),
            customer: formBuilder.group({
                id: optional(contact?.customer?.id),
                name: required(contact?.customer?.name),
                reference: required(contact?.customer?.reference),
                city: required(contact?.customer?.city),
                country: required(contact?.customer?.country),
                email: optional(contact?.customer?.email),
                name1: required(contact?.customer?.name1),
                name2: optional(contact?.customer?.name2),
                partner: optional(contact?.customer?.partner),
                street: required(contact?.customer?.street),
                streetNumber: optional(contact?.customer?.streetNumber),
                tel: optional(contact?.customer?.tel),
                zip: required(contact?.customer?.zip),
            }),
            name1: optional(contact?.name1),
            name2: optional(contact?.name2),
            partner: optional(contact?.partner),
            street: optional(contact?.street),
            streetNumber: optional(contact?.streetNumber),
            country: optional(contact?.country),
            zip: optional(contact?.zip),
            city: optional(contact?.city),
            tel: optional(contact?.tel),
            email: optional(contact?.email)
        });
    }

    createInformationGroup(information: ShipmentInformation, days: number) {
        return this.formBuilder.group({
            id: optional(information?.id),
            date: required(information?.date || moment().add(days, 'days').format('YYYY-MM-DD')),
            since: required(information?.since || '08:00'),
            until: required(information?.until || '17:00'),
            notice: optional(information?.notice),
            reference: optional(information?.reference)
        });
    }

    createPackageGroup(packages: ShipmentPackage[] = [{} as ShipmentPackage]): FormArray<ShipmentPackage> {
        const array = this.formBuilder.array<ShipmentPackage>([]);
        packages.forEach(pack => {
            const control: FormGroup<ShipmentPackage> = this.formBuilder.group<ShipmentPackage>({
                id: optional(pack.id),
                name: optional(pack.name),
                length: required(pack.length || 1),
                width: required(pack.width || 1),
                height: required(pack.height || 1),
                weight: required(pack.weight || 1),
                volumeWeight: required(pack.volumeWeight || (1 / 5000))
            });
            array.push(control);
        });
        return array;
    }

}
