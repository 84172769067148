import { Component, OnInit } from '@angular/core';
import { Shipment, ShipmentPackage } from '../services/shipment/models/shipment';
import { LocalStorageContext } from '../storage/local-storage.context';
import { ContextKey } from '../storage/context.key';
import { LoginResponse } from '../login/models/login.response';
import { CustomerService } from '../services/customer/customer.service';
import { UserService } from '../services/user/user.service';
import { User } from '../services/user/models/user';
import { Customer } from '../services/customer/models/customer';
import { ShipmentService } from '../services/shipment/shipment.service';
import * as moment from 'moment';
import * as toastr from 'toastr';

interface CSV {
    recipient_reference: string;
    sender_name: string;
    worth: string;
    recipient_tel: string;
    package_number: string;
    sender_country: string;
    recipient_street: string;
    recipient_country: string;
    has_insurance: string;
    recipient_city: string;
    id: string;
    recipient_date: string;
    package_info: string;
    sender_reference: string;
    is_document: string;
    recipient_zip: string;
    is_pod: string;
    height: string;
    sender_email: string;
    recipient_info: string;
    sender_since: string;
    sender_contact: string;
    product: string;
    sender_date: string;
    sender_info: string;
    length: string;
    recipient_email: string;
    weight: string;
    sender_tel: string;
    sender_zip: string;
    sender_city: string;
    recipient_street_number: string;
    recipient_since: string;
    width: string;
    recipient_until: string;
    sender_street_number: string;
    sender_until: string;
    recipient_name: string;
    sender_street: string;
    recipient_contact: string
}

@Component({
    selector: 'app-importer',
    templateUrl: './importer.component.html',
    styleUrls: ['./importer.component.scss']
})
export class ImporterComponent implements OnInit {
    private user: User;
    private customer: Customer;

    file: File;
    shipments: Shipment[] = [];

    saving = false;

    constructor(private readonly customerService: CustomerService,
                private readonly userService: UserService,
                private readonly shipmentService: ShipmentService) {
    }

    async ngOnInit() {
        const user = LocalStorageContext.get<LoginResponse>(ContextKey.login)?.user;
        this.user = await this.userService.getUser(user.id).toPromise();
        this.customer = await this.customerService.findCustomer(user.customer.id).toPromise();
    }

    fileSelected(files: FileList) {

        this.file = files.item(0);
        const reader = new FileReader();
        reader.readAsText(this.file, 'ISO-8859-1');
        reader.onloadend = async () => {
            const text = reader.result as string;
            const items = text.split('\r\n');
            items.shift();

            const mapped = items.map(line => {
                const item = line.split(';');
                return {
                    id: item[0],
                    package_number: item[1],
                    package_info: item[2],
                    length: item[3],
                    width: item[4],
                    height: item[5],
                    weight: item[6],
                    product: item[7],
                    sender_date: item[8],
                    sender_since: item[9],
                    sender_until: item[10],
                    sender_reference: item[11],
                    sender_info: item[12],
                    sender_name: item[13],
                    sender_contact: item[14],
                    sender_street: item[15],
                    sender_street_number: item[16],
                    sender_country: item[17],
                    sender_zip: item[18],
                    sender_city: item[19],
                    sender_tel: item[20],
                    sender_email: item[21],

                    recipient_date: item[22],
                    recipient_since: item[23],
                    recipient_until: item[24],
                    recipient_reference: item[25],
                    recipient_info: item[26],
                    recipient_name: item[27],
                    recipient_contact: item[28],
                    recipient_street: item[29],
                    recipient_street_number: item[30],
                    recipient_country: item[31],
                    recipient_zip: item[32],
                    recipient_city: item[33],
                    recipient_tel: item[34],
                    recipient_email: item[35],

                    is_document: item[36],
                    has_insurance: item[37],
                    is_pod: item[38],
                    worth: item[39],
                };
            });

            this.shipments = this.createShipments(mapped);
        }
    }

    async save() {
        this.saving = true;
        try {
            for (const shipment of this.shipments) {
                await this.shipmentService.save(shipment).toPromise();
            }
            this.shipments = [];
            this.file = undefined;
        } catch (e) {
            this.saving = false;
            toastr.error('Die Daten konnten nicht gespeichert werden!');
            throw e;
        }
        this.saving = false;
        toastr.info('Die Daten wurden erfolgreich importiert!');
    }

    private createShipments(mapped: CSV[]): Shipment[] {
        const shipments: Record<string, Shipment> = {};

        for (const csv of mapped) {
            if (shipments[csv.id]) {
                continue;
            }
            if (!csv.product) {
                continue;
            }
            let weight = 0.0;
            const packages = mapped.filter(current => current.id === csv.id).map(pack => {
                weight += parseFloat(pack.weight);
                return {
                    id: undefined,
                    height: parseFloat(pack.height),
                    width: parseFloat(pack.width),
                    length: parseFloat(pack.length),
                    name: pack.package_info,
                    weight: parseFloat(pack.weight),
                    volumeWeight: parseFloat(pack.weight)
                } as ShipmentPackage
            });

            shipments[csv.id] = {
                id: null,
                user: this.user,
                customer: this.customer,
                packageCount: packages.length,
                weight,
                info: undefined,
                product: { name: csv.product },
                hasInsurance: !!csv.has_insurance,
                isDocument: !!csv.is_document,
                isCod: !!csv.is_pod,
                worth: csv.worth ? parseFloat(csv.worth) : undefined,
                sender: {
                    id: null,
                    sourceContact: null,
                    city: csv.sender_city,
                    country: csv.sender_country,
                    email: csv.sender_country,
                    customer: this.customer,
                    name1: csv.sender_name,
                    name2: null,
                    partner: csv.sender_contact,
                    street: csv.sender_street,
                    streetNumber: csv.sender_street_number,
                    tel: csv.sender_tel,
                    zip: csv.sender_zip
                },
                senderInformation: {
                    id: null,
                    date: moment(csv.sender_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                    since: csv.sender_since + ':00',
                    until: csv.sender_until + ':00',
                    notice: csv.sender_info,
                    reference: csv.sender_reference
                },
                recipient: {
                    id: null,
                    sourceContact: null,
                    city: csv.recipient_city,
                    country: csv.recipient_country,
                    email: csv.recipient_email,
                    customer: this.customer,
                    name1: csv.recipient_name,
                    name2: null,
                    partner: csv.recipient_contact,
                    street: csv.recipient_street,
                    streetNumber: csv.recipient_street_number,
                    tel: csv.recipient_tel,
                    zip: csv.recipient_zip
                },
                recipientInformation: {
                    id: null,
                    date: moment(csv.recipient_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                    since: csv.recipient_since + ':00',
                    until: csv.recipient_until + ':00',
                    notice: csv.recipient_info,
                    reference: csv.recipient_reference
                },
                packages
            };
        }
        return Object.keys(shipments).map(key => shipments[key]);
    }
}
