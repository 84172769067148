<div class="login row background justify-content-center">
    <div class="col-5 welcome">
        <img class="logo" src="/assets/logo.png" alt="Intersprint Overnight">
        <div class="d-flex vh-100">
            <div class="d-flex w-100 h-50 align-self-center flex-md-column card">
                <h1>
                    Willkommen
                </h1>
                <small>Intersprint Overnight Buchung System v2.0</small>
                <small>Melden Sie sich bitte mit Ihrem Benutzername und Passwort ein!</small>

                <div class="form-group icon">
                    <i class="fa  fa-user-o"></i>
                    <input id="username" [formControl]="username" type="text" class="w-100"
                           placeholder="Ihr Benutzername">
                    <small class="error animated fadeIn" *ngIf="username.invalid && (username.dirty || username.touched)">
                        Benutzer darf nicht leer sein!
                    </small>
                </div>

                <div class="form-group icon">
                    <i class="fa  fa-keyboard-o"></i>
                    <input id="password" [formControl]="password" type="password" class="w-100" (keyup.enter)="login()"
                           placeholder="Ihr Passwort">
                    <small class="error animated fadeIn" *ngIf="password.invalid && (password.dirty || password.touched)">
                        Passwort darf nicht leer sein!
                    </small>
                </div>

                <div class="alert alert-danger animated bounceIn" *ngIf="error">{{error}}</div>

                <button class="w-100 btn btn-primary" [disabled]="inProgress" (click)="login()">
                    <i class="fa  fa-sign-in"></i>
                    {{inProgress ? 'Bitte warten...' : 'Anmelden' }}
                </button>
            </div>
        </div>
    </div>
    <footer>
        Made with <span style="color: #e25555;">&hearts;</span> by <a href="https://nodify.at" target="_blank">nodify</a>
    </footer>
</div>
