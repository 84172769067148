import { Directive, HostBinding, Input, Self } from '@angular/core';
import { NgControl }                           from '@angular/forms';


@Directive({
    selector: '[appInputHasError]'
})
export class InputHasErrorDirective {

    @Input() public class: string;

    constructor(@Self() private ngControl: NgControl) {
    }

    @HostBinding('class.error')
    get isInvalid(): boolean {
        return this.invalid;
    }

    public get invalid(): boolean {
        return !this.ngControl.pending && !this.ngControl.valid && (this.ngControl.touched || this.ngControl.dirty);
    }


}
