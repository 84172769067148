export enum SortDirection {
    DESC = 'DESC',
    ASC  = 'ASC'
}

export interface SearchRequest {
    term?: string;
    page?: number;
    size?: number;
    field?: string;
    sort?: SortDirection;
}

export const searchRequest: Partial<SearchRequest> = {
    page: 0,
    size: 25,
    field: 'pid',
    sort: SortDirection.ASC
};
