import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@ng-stack/forms';
import * as toastr from 'toastr';
import { CustomerService } from '../../services/customer/customer.service';
import { Customer } from '../../services/customer/models/customer';

@Component({
    selector: 'app-create-customer',
    templateUrl: './create-customer.component.html',
    styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {

    readonly customer: FormGroup<Customer>;

    entries: any[] = [];

    constructor(private readonly formBuilder: FormBuilder,
                private readonly activatedRoute: ActivatedRoute,
                private readonly router: Router,
                private readonly customerService: CustomerService) {
        this.customer = this.formBuilder.group<Customer>({
            id: [undefined],
            name: ['', Validators.required],
            reference: ['', Validators.required],
            name1: ['', Validators.required],
            name2: [''],
            partner: [''],
            street: ['', Validators.required],
            streetNumber: [''],
            country: ['', Validators.required],
            zip: ['', Validators.required],
            city: ['', Validators.required],
            tel: [''],
            email: ['']
        });

        const {id} = this.activatedRoute.snapshot.params;
        if (id) {
            this.customerService.statistics(id).subscribe(response =>
                this.entries = response.map(entry => ({name: entry.date, value: entry.value})));
            this.customerService.findCustomer(id).subscribe(customer => this.customer.patchValue(customer));
        }
    }

    ngOnInit(): void {
    }

    save() {
        this.customerService.save(this.customer.value).subscribe(response => {
            toastr.info('Kunden Daten wurden erfolgreich gespeichert!');
            this.customer.patchValue(response);
            this.router.navigate(['/customers']);
        });
        return false;
    }

}
