import { Injectable }                                                                from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable }                                                                from 'rxjs';
import { LocalStorageContext }                                                       from '../storage/local-storage.context';
import { ContextKey }                                                                from '../storage/context.key';
import { LoginResponse }                                                             from '../login/models/login.response';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private readonly  router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const response: LoginResponse = LocalStorageContext.get(ContextKey.login);
        if (response && typeof response.token !== 'undefined') {
            return true;
        }
        this.router.navigate(['/auth/sign-in']);
        return false;
    }
}
