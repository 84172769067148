import { Component, Input, OnInit } from '@angular/core';
import { FormGroup }                from '@angular/forms';
import { FormArray }                from '@ng-stack/forms';
import { ShipmentPackage }          from '../../../services/shipment/models/shipment';

@Component({
    selector: 'app-booking-package',
    templateUrl: './booking-package.component.html',
    styleUrls: ['./booking-package.component.scss', '../create-booking.component.scss']
})
export class BookingPackageComponent implements OnInit {

    @Input() packageGroup: FormArray<ShipmentPackage>;

    constructor() {

    }

    ngOnInit(): void {
    }

}
