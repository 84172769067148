<div class="form row" [formGroup]="customer">
    <div class="col-6">
        <fieldset>
            <legend>
                <i class="fas fa-id-card"></i>
                Kunde Informationen
            </legend>
            <div class="form-row">
                <app-custom-input label="Name *" icon="far fa-id-badge" inputName="name" placeholder="Kunden Name"></app-custom-input>
                <app-custom-input label="Referenz Nummer *" icon="fas fa-calculator" inputName="reference"
                                  placeholder="Kunden Nummer (Aus Kurier Manager)"></app-custom-input>
            </div>
            <h6>Kontakt Daten</h6>
            <div>
                <div class="form-row">
                    <app-custom-input label="Name *" icon="far fa-id-badge" inputName="name1"
                                      placeholder="Name vom Kontakt"></app-custom-input>
                    <app-custom-input label="Name 2" icon="far fa-clipboard" inputName="name2"
                                      placeholder="Name 2 vom Kontakt"></app-custom-input>
                </div>

                <div class="form-row">
                    <div class="form-group col icon">
                        <i class="fas fa-road"></i>
                        <label for="street">Straße *</label>
                        <app-address-autocomplete
                            id="street"
                            [contact]="customer"
                            [adressType]="'geocode'">
                        </app-address-autocomplete>
                    </div>
                    <app-custom-input label="Haus Nummer" icon="fas fa-door-open" inputName="streetNumber"
                                      placeholder="Haus Nummer"></app-custom-input>
                </div>

                <div class="form-row">
                    <app-custom-input label="PLZ *" icon="fas fa-mail-bulk" inputName="zip" placeholder="PLZ eingeben"></app-custom-input>
                    <app-custom-input label="Ort *" icon="fas fa-map-marker-alt" inputName="city"
                                      placeholder="Ort eingeben"></app-custom-input>
                    <app-custom-input label="Land *" icon="fas fa-globe-europe" inputName="country"
                                      placeholder="Land eingeben"></app-custom-input>
                </div>

                <div class="form-row">
                    <app-custom-input label="Tel" type="tel" icon="fas fa-phone-square-alt" inputName="tel"
                                      placeholder="Telefonnummer eingeben"></app-custom-input>
                    <app-custom-input label="E-Mail *" type="email" icon="fas fa-envelope-open-text" inputName="email"
                                      placeholder="E-Mail Adresse eingeben"></app-custom-input>
                    <app-custom-input label="Ansprech Partner" icon="fas fa-user-check" inputName="partner"
                                      placeholder="Ansprech Partner eingeben"></app-custom-input>
                </div>
            </div>
        </fieldset>
    </div>
    <div class="col-6">
        <span>
                Aufträge nach Datum
            </span>
        <ngx-charts-advanced-pie-chart [results]="entries"></ngx-charts-advanced-pie-chart>
    </div>

</div>
<app-action-bar (save)="save()" cancel="/customers/" [disabled]="customer.invalid"></app-action-bar>
