import { Component, EventEmitter, OnInit } from '@angular/core';
import { SearchResponse }                  from '../../services/models/search.response';
import { SearchRequest }                   from '../../services/models/search.request';
import { UserService }                     from '../../services/user/user.service';
import { User }                            from '../../services/user/models/user';
import { LocalStorageContext }             from '../../storage/local-storage.context';
import { LoginResponse }                   from '../../login/models/login.response';
import { ContextKey }                      from '../../storage/context.key';

@Component({
    selector: 'app-list-user',
    templateUrl: './list-user.component.html',
    styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {

    currentUser: User;
    size = 25;
    onSearchResponse: EventEmitter<SearchResponse<any>> = new EventEmitter<SearchResponse<any>>();

    constructor(private readonly userService: UserService) { }

    ngOnInit() {
        this.currentUser = LocalStorageContext.get<LoginResponse>(ContextKey.login).user;
    }

    search(request: SearchRequest) {
        this.userService.search(request).subscribe(response => this.onSearchResponse.emit(response));
    }

}
