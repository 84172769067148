import { Shipment } from '../../services/shipment/models/shipment';
import { ChangeRequest, Changes } from '../../services/shipment/models/change.request';

export class ChangeDetectorService {

    static detect(current: Shipment, changed: Shipment): ChangeRequest {
        if (!current) {
            return;
        }
        const changes: Changes[] = [];
        const packets: Changes[] = [];

        if (current.product?.name !== changed.product.name) {
            changes.push({ description: 'Produkt', current: current.product?.name, changed: changed.product?.name })
        }

        this.detectChangesFor('Absender', 'sender', current, changed, changes);
        this.detectChangesFor('Empfänger', 'recipient', current, changed, changes);

        if (changed.isDocument !== current.isDocument) {
            changes.push({
                description: 'Dokument?',
                current: current.isDocument ? 'Ja' : 'Nein',
                changed: changed.isDocument ? 'Ja' : 'Nein'
            })
        }

        if (changed.hasInsurance !== current.hasInsurance) {
            changes.push({
                description: 'Ist Versichert ?',
                current: current.hasInsurance ? 'Ja' : 'Nein',
                changed: changed.hasInsurance ? 'Ja' : 'Nein'
            })
        }

        if (changed.worth !== current.worth) {
            changes.push({
                description: 'Gesamt Wert',
                current: current.worth?.toString(),
                changed: changed.worth?.toString()
            })
        }

        if (changed.info !== current.info) {
            changes.push({
                description: 'Notizen',
                current: current.info,
                changed: changed.info
            })
        }


        if (changed.packageCount !== current.packageCount) {
            changes.push({
                description: 'Anzahl der Pakete',
                current: current.packageCount?.toString(),
                changed: changed.packageCount?.toString()
            })
        }

        const index = changed.packages?.length > current.packages?.length ? changed.packages.length : current.packages.length;

        for (let i = 0; i < index; i++) {
            const packCurrent = changed.packages.length > i ? changed.packages[i] : undefined;
            const packExisting = current.packages.length > i ? current.packages[i] : undefined;

            if (packCurrent?.name !== packExisting?.name) {
                packets.push({
                    description: 'Name',
                    current: packExisting?.name || '-',
                    changed: packCurrent?.name || '-'
                })
            }

            if (packCurrent?.length !== packExisting?.length) {
                packets.push({
                    description: 'Länge',
                    current: packExisting?.length?.toString() || '-' ,
                    changed: packCurrent?.length?.toString() || '-'
                })
            }

            if (packCurrent?.width !== packExisting?.width) {
                packets.push({
                    description: 'Breite',
                    current: packExisting?.width?.toString() || '-',
                    changed: packCurrent?.width?.toString() || '-'
                })
            }

            if (packCurrent?.height !== packExisting?.height) {
                packets.push({
                    description: 'Höhe',
                    current: packExisting?.height?.toString() || '-',
                    changed: packCurrent?.height?.toString() || '-'
                })
            }

            if (packCurrent?.weight !== packExisting?.weight) {
                packets.push({
                    description: 'Gewicht',
                    current: packExisting?.weight?.toString() || '-',
                    changed: packCurrent?.weight?.toString() || '-'
                })
            }
        }

        if (changed.packageCount !== current.packageCount) {
            changes.push({
                description: 'Anzahl der Pakete',
                current: current.packageCount?.toString() || '-',
                changed: changed.packageCount?.toString() || '-'
            })
        }

        return { changes, packets, shipment: current.id };
    }

    private static detectChangesFor(label: string, field: string, current: Shipment, changed: Shipment, changes: Changes[]) {
        if (current[field + 'Information'].date !== changed[field + 'Information'].date) {
            changes.push({
                description: label + ' Datum',
                current: current[field + 'Information'].date,
                changed: changed[field + 'Information'].date
            })
        }

        if (current[field + 'Information'].since !== changed[field + 'Information'].since) {
            changes.push({
                description: label + ' Von',
                current: current[field + 'Information'].since,
                changed: changed[field + 'Information'].since
            })
        }

        if (current[field + 'Information'].until !== changed[field + 'Information'].until) {
            changes.push({
                description: label + ' Bis',
                current: current[field + 'Information'].until,
                changed: changed[field + 'Information'].until
            })
        }

        if (current[field + 'Information'].reference !== changed[field + 'Information'].reference) {
            changes.push({
                description: label + ' Referenz',
                current: current[field + 'Information'].reference,
                changed: changed[field + 'Information'].reference
            })
        }

        if (current[field + 'Information'].notice?.trim() !== changed[field + 'Information'].notice?.trim()) {
            changes.push({
                description: label + ' Notizen',
                current: current[field + 'Information'].notice,
                changed: changed[field + 'Information'].notice
            })
        }


        if (current[field].name1 !== changed[field].name1) {
            changes.push({
                description: label + ' Name',
                current: current[field].name1,
                changed: changed[field].name1
            })
        }

        if (current[field].partner !== changed[field].partner) {
            changes.push({
                description: label + ' Ansprechpartner',
                current: current[field].partner,
                changed: changed[field].partner
            })
        }

        if (current[field].street !== changed[field].street) {
            changes.push({
                description: label + ' Straße',
                current: current[field].street,
                changed: changed[field].street
            })
        }

        if (current[field].streetNumber !== changed[field].streetNumber) {
            changes.push({
                description: label + ' Straße Nummer',
                current: current[field].streetNumber,
                changed: changed[field].streetNumber
            })
        }


        if (current[field].zip !== changed[field].zip) {
            changes.push({
                description: label + ' PLZ',
                current: current[field].zip,
                changed: changed[field].zip
            })
        }

        if (current[field].city !== changed[field].city) {
            changes.push({
                description: label + 'Stadt',
                current: current[field ].city,
                changed: changed[field ].city
            })
        }

        if (current[field].tel !== changed[field].tel) {
            changes.push({
                description: label + ' Telefon Nummer',
                current: current[field].tel,
                changed: changed[field].tel
            })
        }

        if (current[field].email !== changed[field].email) {
            changes.push({
                description: label + ' E-Mail',
                current: current[field].email,
                changed: changed[field].email
            })
        }
    }

}
