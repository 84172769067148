<nav class="navbar navbar-expand-md navbar-light bg-light" *ngIf="user">
    <a href="#" class="navbar-brand">
        <img id="logo" src="/assets/logo.png" alt="Intersprint Overnight GmbH.">
    </a>
    <section class="section section--menu" id="Yourstyle">
        <span class="link-copy"></span>
        <div class="menu menu--iris">
            <ul class="menu__list">
                <li class="menu__item" routerLink="/bookings/" (click)="reset()" routerLinkActive="menu__item--current" [routerLinkActiveOptions]="{ exact: true}">
                    <a class="menu__link">
                        <i class="fa fa-file-alt"></i>
                        Aufträge
                    </a>
                </li>
                <li class="menu__item" routerLink="/bookings/create" (click)="reset()" routerLinkActive="menu__item--current" [routerLinkActiveOptions]="{ exact: true}">
                    <a class="menu__link">
                        <i class="far fa-plus-square"></i>
                        Auftrag Erstellen
                    </a>
                </li>
                <li class="menu__item" routerLink="/import" (click)="reset()" routerLinkActive="menu__item--current" [routerLinkActiveOptions]="{ exact: true}">
                    <a class="menu__link">
                        <i class="far fa-plus-square"></i>
                        Importieren
                    </a>
                </li>
                <li class="menu__item" routerLink="/reports/" (click)="reset()" routerLinkActive="menu__item--current" [routerLinkActiveOptions]="{ exact: true}">
                    <a class="menu__link">
                        <i class="fa fa-file-alt"></i>
                        Tages Abschlüsse
                    </a>
                </li>
                <li class="menu__item" routerLink="/contacts" routerLinkActive="menu__item--current" (click)="reset()" [routerLinkActiveOptions]="{ exact: true}">
                    <a class="menu__link">
                        <i class="far fa-id-card"></i>
                        Kontakte
                    </a>
                </li>
                <li class="menu__item" routerLink="/users" routerLinkActive="menu__item--current" *ngIf="user.role.id === 4 || user.role.id === 3" (click)="reset()" [routerLinkActiveOptions]="{ exact: true}">
                    <a class="menu__link">
                        <i class="far fa-id-card"></i>
                        Benutzerdaten
                    </a>
                </li>
                <li class="menu__item" routerLink="/customers" routerLinkActive="menu__item--current" *ngIf="user.role.id === 4" (click)="reset()" [routerLinkActiveOptions]="{ exact: true}">
                    <a class="menu__link">
                        <i class="far fa-user-circle"></i>
                        Kunden
                    </a>
                </li>
                <li class="menu__item" [routerLink]="'/users/' + user.id" routerLinkActive="menu__item--current" (click)="reset()" [routerLinkActiveOptions]="{ exact: true}">
                    <a class="menu__link">
                        <i class="far fa-user-circle"></i>
                        Mein Profil
                    </a>
                </li>
                <li class="menu__item logout" (click)="logout()">
                    <a class="menu__link">
                        {{ user.firstName }} {{ user.lastName }} ({{ user.role.description }})
                        <i class="fas fa-sign-out-alt"></i>
                        <small>Abmelden</small>
                    </a>
                </li>
            </ul>
        </div>
    </section>
</nav>
