<ng-template #headers>
    <div class="col-2 sortable" (click)="sort('pid')">
        # ID
        <i class="fas fa-sort" *ngIf="field !== 'pid'"></i>
        <i class="fas fa-sort-up" *ngIf="direction === 'ASC' && field === 'pid'"></i>
        <i class="fas fa-sort-down" *ngIf="direction === 'DESC' && field === 'pid'"></i>
    </div>
    <div class="col-4 sortable" (click)="sort('date_sortable')">
        Datum

        <i class="fas fa-sort" *ngIf="field !== 'date_sortable'"></i>
        <i class="fas fa-sort-up" *ngIf="direction === 'ASC' && field === 'date_sortable'"></i>
        <i class="fas fa-sort-down" *ngIf="direction === 'DESC' && field === 'date_sortable'"></i>
    </div>
    <div class="col-3 sortable" (click)="sort('customer.name_sortable')">
        Firma

        <i class="fas fa-sort" *ngIf="field !== 'customer.name_sortable'"></i>
        <i class="fas fa-sort-up" *ngIf="direction === 'ASC' && field === 'customer.name_sortable'"></i>
        <i class="fas fa-sort-down" *ngIf="direction === 'DESC' && field === 'customer.name_sortable'"></i>
    </div>
    <div class="col-2 sortable" (click)="sort('total_shipments_sortable')">
        Anzahl der Sendungen

        <i class="fas fa-sort" *ngIf="field !== 'total_shipments_sortable'"></i>
        <i class="fas fa-sort-up" *ngIf="direction === 'ASC' && field === 'total_shipments_sortable'"></i>
        <i class="fas fa-sort-down" *ngIf="direction === 'DESC' && field === 'total_shipments_sortable'"></i>
    </div>
    <div class="col-1">
        Aktionen
    </div>
</ng-template>

<ng-template #body let-data="result">
    <div class="row align-items-center" *ngFor="let report of data">
        <div class="col-2">
            {{ report.id.toString().padStart(8, '0')}}
        </div>
        <div class="col-4">
            {{ report.date | date: 'dd.MM.yyyy' }}
        </div>
        <div class="col-3">
            <div class="row">
                <div class="col-12 name"> {{report.customer.name1}} </div>
                <div class="col-12"> {{report.customer.street}}   {{report.customer.streetNumber}} </div>
                <div class="col-12"> {{report.customer.city}} -  {{report.customer.country}} </div>
            </div>
        </div>
        <div class="col-2">
            {{report.totalShipments}}
        </div>
        <div class="col-1">
            <div class="print" (click)="createPdf(report, $event)">
                <i class="fas fa-print"></i>
            </div>
        </div>
    </div>
</ng-template>
<app-table (search)="search($event)"
           emptyText="Sie haben noch keine Tagesabschlüsse erstellt, bitte auf Erstellen klicken um einen Tagesabschluss zu erstellen"
           [enableRoute]="false"
           (creating)="create()"
           [headers]="headers"
           [body]="body" [onSearchResponse]="onSearchResponse"
           [size]="size"></app-table>
