import { Component, OnInit }                  from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@ng-stack/forms';
import { Contact }                            from '../../services/shipment/models/contact';
import { User }                               from '../../services/user/models/user';
import { LocalStorageContext }                from '../../storage/local-storage.context';
import { ContextKey }                         from '../../storage/context.key';
import { LoginResponse }                      from '../../login/models/login.response';
import { ActivatedRoute, Router }             from '@angular/router';
import { ContactService }                     from '../../services/contact/contact.service';
import * as toastr                            from 'toastr';

@Component({
    selector: 'app-create-contact',
    templateUrl: './create-contact.component.html',
    styleUrls: ['./create-contact.component.scss']
})
export class CreateContactComponent implements OnInit {

    contact: FormGroup<Contact>;
    user: User;

    constructor(private readonly formBuilder: FormBuilder,
                private readonly activeRoute: ActivatedRoute,
                private readonly router: Router,
                private readonly contactService: ContactService) {
    }

    ngOnInit() {
        const {id} = this.activeRoute.snapshot.params;

        this.user = LocalStorageContext.get<LoginResponse>(ContextKey.login).user;
        this.contact = this.formBuilder.group<Contact>({
            id: [undefined],
            name1: ['', Validators.required],
            name2: [''],
            email: ['', [Validators.required, Validators.email]],
            country: ['', Validators.required],
            city: ['', Validators.required],
            zip: ['', Validators.required],
            street: ['', Validators.required],
            streetNumber: [''],
            partner: [''],
            tel: ['']
        });

        if (id) {
            this.contactService.findById(id).subscribe(contact => {
                this.contact.patchValue(contact);
            });
        }
    }

    save() {
        if (this.contact.invalid) {
            toastr.error('Kontakt Daten sind ungültig! Bitte füllen Sie die Pflichfelder aus!');
            this.contact.markAllAsTouched();
            return false;
        }
        this.contactService.save(this.contact.value).subscribe(_ => {
            toastr.info('Kontak Daten wurden aktualisiert.');
            return this.router.navigate(['/contacts']);
        });
        return false;
    }

    delete() {
        if (!this.contact.value.id) {
            toastr.error('Kontakt ist nocht nicht erstellt. Bitte zuerst speichern um ein Kontakt zu löschen');
            this.contact.markAllAsTouched();
            return false;
        }

        this.contactService.delete(this.contact.value.id).subscribe(_ => {
            toastr.info('Kontakt Daten wurden gelöscht.');
            return this.router.navigate(['/contacts']);
        });
        return false;
    }

    private findComponent(place: any, type: string, shortName: boolean = false): string {
        const component = place.address_components.find(current => current.types.includes(type));
        if (!component) {
            return undefined;
        }

        if (shortName) {
            return component.short_name;
        }
        return component.long_name;
    }
}
