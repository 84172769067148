<ng-template #headers>
    <div class="col-3">
        Name
    </div>
    <div class="col-2">
        Benutzename
    </div>
    <div class="col-3">
        E-Mail
    </div>
    <div class="col-2">
        Tel
    </div>
    <div class="col-2">
        Berechtigung
    </div>
</ng-template>

<ng-template #body let-data="result">
    <div class="alert alert-success" *ngIf="currentUser.role.name === 'ROOT'">
        Sie haben die Admin Berechtigung und dürfen alle Benutzer im System sehen und bearbeiten.
    </div>
    <div class="row align-items-center" *ngFor="let user of data; let i = index;" routerLink="/users/{{user.id}}">
        <div class="col-3">
            {{user.firstName}} {{ user.lastName }}
            <div class="customer" *ngIf="currentUser.role.name === 'ROOT'">
                {{ user.customer.name }}
            </div>

        </div>
        <div class="col-2">
            {{user.username}}
        </div>
        <div class="col-3">
            {{ user.email }}
        </div>
        <div class="col-2">
            {{ user.tel || '-' }}        </div>
        <div class="col-2">
            {{ user.role?.name || '-' }}
        </div>
    </div>
</ng-template>

<app-table (search)="search($event)" [body]="body" [headers]="headers" [size]="size" [onSearchResponse]="onSearchResponse"></app-table>
