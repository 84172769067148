import { ContextKey }   from './context.key';
import { EventEmitter } from '@angular/core';

export class LocalStorageContext {

    static emitter: EventEmitter<ContextKey> = new EventEmitter<ContextKey>();

    static get<T>(key: ContextKey): T {
        const value = localStorage.getItem(key);
        if (!value) {
            return undefined;
        }
        return JSON.parse(value);
    }

    static save(key: ContextKey, entity: any) {
        localStorage.setItem(key, JSON.stringify(entity));
        this.emitter.emit(key);
    }

    static delete(key: ContextKey) {
        localStorage.removeItem(key);
    }
}
