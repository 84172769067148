<ng-template #rt let-customer="result" let-t="term">
    <div class="row">
        <div class="col-12 name">
            {{customer.name}}
        </div>
    </div>
</ng-template>

<div class="form">
    <div class="row">
        <fieldset [formGroup]="user">
            <legend>
                <i class="fas fa-id-card"></i>
                Benutzer Bearbeiten
            </legend>
            <div class="small">
                Bitte geben Sie die Benutzer- Informationen ein.
                <div>Jeder Benutzer darf unterschiedliche Rechte haben.
                    Es gilt folgendes:
                    <ul>
                        <li>
                            <strong>Mitarbeiter</strong> hat folgende Berechtigungen:
                            <br>
                            <i>Aufträge erstellen / bearbeiten / auflisten </i>
                            <br/>
                            <i>Tägliche Berichte erstellen / auflisten / im Detail sehen </i>
                        </li>
                        <li>
                            <strong>Firmen Admin</strong> hat zusätzlich folgende Berechtigungen:
                            <br>
                            <i>Alle eigene Benutzer sehen / erstellen / auflisten / ändern / löschen</i>
                        </li>
                        <li *ngIf="current.role.name === 'ROOT'">
                            <strong>System Admin</strong> hat zusätzlich folgende Berechtigungen.
                            <br>
                            <small class="badge-danger">Diese Berechtigung darf nur für Intersprint Mitarbeiter eingegeben werden!!</small>
                            <br>
                            <i>Alle Aufträge am System sehen / bearbeiten und erstellen</i> <br>
                            <i>Alle Berichte sehen / erstellen / löschen <b> Achtung!: Wenn ein System Admin auf Erstellen drückt, wird der
                                Bericht für alle Kunden automatisch erstellen!</b></i> <br>
                            <i>Kunden Daten sehen / erstellen / bearbeiten</i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="form-row">
                <app-custom-input label="Name *" icon="far fa-id-badge" inputName="firstName"
                                  placeholder="Name Eingeben"></app-custom-input>
                <app-custom-input label="Nachname *" icon="far fa-id-badge" inputName="lastName"
                                  placeholder="Nachname eingeben"></app-custom-input>
            </div>

            <div class="form-row">
                <app-custom-input label="Tel" inputName="tel" type="tel" icon="fas fa-phone-square-alt"
                                  placeholder="Telefonnummer eingeben"></app-custom-input>
                <app-custom-input label="E-Mail *" inputName="email" type="email" icon="fas fa-envelope-open-text"
                                  placeholder="E-Mail Adresse eingeben"></app-custom-input>
            </div>

            <div class="form-row">
                <app-custom-input icon="fas fa-user-lock" label="Benutzername *" inputName="username"
                                  placeholder="Benutzername eingeben"></app-custom-input>
                <div class="form-group col">
                    <label for="role">Berechtigung *</label>
                    <select id="role"
                            [compareWith]="isRoleSame"
                            formControlName="role"
                            appInputHasError
                            class="form-control custom-select">
                        <option value="0">Berechtigung auswählen</option>
                        <option *ngFor="let role of roles" [ngValue]="role">
                            {{role.description}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-row">
                <app-custom-input icon="fas fa-key" label="Passwort *" inputName="password" placeholder="Passwort eingeben"
                                  type="password"></app-custom-input>
                <div class="form-group col">
                    <label for="password-reenter">Passwort wiederholen *</label>
                    <input type="password" id="password-reenter" [formControl]="passwordVerification" class="form-control">
                </div>
            </div>
            <div class="small alert alert-warning">
                Sie können das Passwort für bestehende Benutzer leer lassen, wenn das Passwort gleich bleiben muss.
            </div>
            <div class="form-row" *ngIf="current.role.name === 'ROOT'">
                <div class="form-group col icon" formGroupName="customer">
                    <i class="fas fa-shipping-fast"></i>
                    <label for="customer">Kunde</label>
                    <input type="text"
                           autocomplete="false"
                           id="customer"
                           class="form-control"
                           [ngbTypeahead]="search"
                           (selectItem)="onCustomerSelected($event)"
                           placement="top-left"
                           [value]="user.controls.customer?.controls.name?.value"
                           [inputFormatter]="searchInputFormatter"
                           [resultTemplate]="rt"
                           placeholder="Kunde auswählen"
                           required>
                </div>
            </div>
            <div class="form-row">
                <div class="checkbox clip-check check-primary checkbox-inline col icon" formGroupName="userSettings">
                    <input type="checkbox" id="send-confirmation-email" class="form-control" formControlName="sendConfirmationEmail">
                    <label for="send-confirmation-email">
                        <i class="fas fa-car-crash"></i>
                        Bestätigung E-Mail erhalten
                    </label>
                </div>
            </div>
        </fieldset>
        <div class="col-6">
        <span>
            Meistens für folgende Kunden Aufträge erstellt.
            </span>
            <ngx-charts-advanced-pie-chart [results]="entries"></ngx-charts-advanced-pie-chart>
        </div>
    </div>
</div>
<app-action-bar [enableDelete]="user.value.id !== undefined" (delete)="delete()" (save)="save()" cancel="/users"></app-action-bar>
