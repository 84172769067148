import { Component, EventEmitter, OnInit } from '@angular/core';
import { SearchResponse }                  from '../../services/models/search.response';
import { Customer }                    from '../../services/customer/models/customer';
import { CustomerService }             from '../../services/customer/customer.service';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { SearchRequest }               from '../../services/models/search.request';

@Component({
    selector: 'app-list-customer',
    templateUrl: './list-customer.component.html',
    styleUrls: ['./list-customer.component.scss']
})
export class ListCustomerComponent implements OnInit {

    size = 25;
    onSearchResponse: EventEmitter<SearchResponse<any>> = new EventEmitter<SearchResponse<any>>();

    constructor(private readonly customerService: CustomerService) {
    }

    ngOnInit() {
    }

    search(request?: SearchRequest) {
        this.customerService.search(request).subscribe(response => this.onSearchResponse.emit(response));
    }

}
