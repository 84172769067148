<div class="form" [formGroup]="shipment">
    <div class="loader animate__animated animate__fadeIn" *ngIf="loading">
        <b>Bitte Warten, die Daten werden geladen...</b>
        <div class="sk-grid">
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-md-4  ">
            <fieldset class="main">
                <legend>
                    <i class="fas fa-truck-loading"></i>
                    Versandangaben
                </legend>
                <small>Bitte geben Sie die Versand und Rechnung Informationen ein.</small>

                <div class="form-row">
                    <div class="form-group col" formGroupName="customer">
                        <label for="invoice-address">Rechnungsadresse *</label>
                        <input type="text" id="invoice-address" class="form-control" formControlName="name">
                    </div>

                    <div class="form-group col">
                        <label for="sending-category">Versandart *</label>
                        <select id="sending-category"
                                [compareWith]="isProductSame"
                                formControlName="product"
                                appInputHasError
                                class="form-control custom-select">
                            <option value="0">Verstandart Auswählen</option>
                            <option *ngFor="let product of products" [ngValue]="product">
                                {{product.name}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-row">

                    <div class="checkbox clip-check check-primary checkbox-inline col icon">
                        <input type="checkbox" id="has-insurance" class="form-control" formControlName="hasInsurance">
                        <label for="has-insurance">
                            <i class="fas fa-car-crash"></i>
                            Versicherung
                        </label>
                    </div>
                    <div class="checkbox clip-check check-primary checkbox-inline col icon">
                        <input type="checkbox" id="is-document" class="form-control" formControlName="isDocument">
                        <label for="is-document">
                            <i class="far fa-file-alt"></i>
                            Dokument
                        </label>
                    </div>
                </div>

                <div class="form-row">
                    <app-custom-input label="Wert" [readonly]="!shipment.controls.hasInsurance.value" type="number" icon="fas fa-euro-sign" inputName="worth" placeholder="Gesamter Warenwert eingeben"></app-custom-input>
                    <app-custom-input label="Gesamt Gewicht *" type="number" icon="fas fa-balance-scale-right" inputName="weight" placeholder="Gesamtes Gewicht eingeben"></app-custom-input>
                </div>
                <div class="form-row">
                    <div class="form-group col icon">
                        <i class="fas fa-book"></i>

                        <label for="additional-notice">Zusatzt Text:</label>
                        <textarea id="additional-notice" class="form-control" formControlName="info" rows="3"></textarea>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col form-group">
                        <label for="package-count">Anzahl von Paketen {{ shipment.get('packageCount').value }} *</label>
                        <input type="number"
                               min="0"
                               max="99"
                               formControlName="packageCount"
                               appInputHasError
                               id="package-count"
                               class="form-control">
                    </div>
                </div>
            </fieldset>

        </div>
        <div class="col-xs-12 col-md-4  ">
            <button class="switch" (click)="switch()"
                    data-toggle="tooltip"
                    placement="bottom"
                    ngbTooltip="Tauschen"><></button>
            <app-booking-contact
                [contact]="shipment.get('sender')"
                [information]="shipment.get('senderInformation')"
                title="Absender Daten"
                infoLabel="Abholinfo"
                section="Absender"
                dateLabel="Abholdatum *"
            >
            </app-booking-contact>
        </div>
        <div class="col-xs-12 col-md-4  ">
            <app-booking-contact [contact]="shipment.get('recipient')"
                                 [information]="shipment.get('recipientInformation')"
                                 title="Empfänger Daten"
                                 infoLabel="Zustellinfo"
                                 section="Empfänger">
            </app-booking-contact>
        </div>
    </div>
    <app-booking-package [packageGroup]="shipment.get('packages')"></app-booking-package>
</div>
<app-action-bar (save)="save()" cancel="/bookings" [disabled]="false"></app-action-bar>

