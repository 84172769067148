<div class="toolbox">
    <div class="row align-items-center">
        <div class="col-7 text-left">
            <button class="btn btn-primary" routerLink="./create" *ngIf="enableRoute">
                <i class="far fa-plus-square"></i>
                Erstellen
            </button>

            <button class="btn btn-primary" *ngIf="!enableRoute" (click)="create()">
                <i class="far fa-plus-square"></i>
                Erstellen
            </button>
        </div>
        <div class="col-5 align-content-end search">
            <div class="input-group">
                <div class="input-group-prepend align-items-center">
                    <i class="fas fa-search"></i>
                </div>
                <input type="text" class="form-control-plaintext" placeholder="Suchen..." [formControl]="searchForm">
            </div>
        </div>
    </div>
</div>
<div class="content">
    <div class="loader" *ngIf="searchResponse.total <= 0">
        <div class="sk-grid">
            <b *ngIf="emptyText">{{emptyText}}</b>

            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
        </div>
    </div>
    <div class="header d-flex">
        <div class="row align-items-center">
            <ng-template [ngTemplateOutlet]="headers"></ng-template>
        </div>

    </div>
    <div class="content-table">
        <div class="body">
            <ng-template [ngTemplateOutlet]="body" [ngTemplateOutletContext]="{ result: searchResponse.data }"></ng-template>
        </div>
    </div>
    <footer>
        <div class="row align-items-center">
            <div class="col-4">
                Seite {{page + 1 }} von {{this.pages}} und {{ page * 25 + 1 }} - {{ max }} von {{ searchResponse.total }} Daten
            </div>
            <div class="col-3">
                Made with <span style="color: #e25555;">&hearts;</span> by <a href="https://nodify.at" target="_blank">nodify</a>
            </div>
            <div class="col-5">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end pagination-sm">
                        <li class="page-item" [class.disabled]="page <= 0">
                            <a class="page-link" href (click)="onPageSelect(0)" tabindex="-1">
                                <span aria-hidden="true">Anfang</span>
                                <span class="sr-only">Anfang</span>
                            </a>
                        </li>
                        <li class="page-item">....</li>

                        <li class="page-item" [class.disabled]="page <= 0">
                            <a class="page-link" href (click)="back($event)" tabindex="-1">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                        <li class="page-item">
                            ......
                        </li>
                        <li class="page-item" [class.disabled]="page + 1 == pages">
                            <a class="page-link" href (click)="next($event)">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                        <li class="page-item">....</li>
                        <li class="page-item" [class.disabled]="page + 1 == pages">
                            <a class="page-link" href (click)="onPageSelect(this.pages - 1)">
                                <span aria-hidden="true">Ende</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </footer>
</div>
