import { Component, Inject, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';
import { FormControl, Validators } from '@angular/forms';
import { LocalStorageContext } from '../storage/local-storage.context';
import { ContextKey } from '../storage/context.key';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as Rollbar from 'rollbar';
import { RollbarService } from '../services/rollbar.service';
import { LoginResponse } from './models/login.response';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    username: FormControl = new FormControl('', [Validators.required]);
    password: FormControl = new FormControl('', [Validators.required]);

    inProgress: boolean;
    error: string;

    constructor(private readonly service: LoginService, private readonly router: Router, @Inject(RollbarService) private rollbar: Rollbar) {
    }

    ngOnInit() {
    }

    login() {
        this.rollbar.configure({
            payload: {
                person: {
                    id: 1, // required
                    username: this.username.value,
                    email: 'booking@intersprint-overnight.at'
                }
            }
        })
        if (this.username.invalid || this.password.invalid) {
            this.username.markAsTouched();
            this.password.markAsTouched();
            return;
        }

        this.inProgress = true;
        this.error = undefined;

        this.service.login(this.username.value, this.password.value)
            .pipe(catchError(exception => {
                this.inProgress = false;
                this.error = 'Ungültiger Benutzername und/oder Passwort!';
                console.error(exception);

                this.username.reset('');
                this.password.reset('');
                return exception;
            }))
            .subscribe((response: LoginResponse) => {
                this.rollbar.configure({
                    payload: {
                        person: {
                            id: response.user.id, // required
                            username: response.user.username,
                            email: response.user.email
                        }
                    }
                })
                LocalStorageContext.save(ContextKey.login, response);
                this.inProgress = false;
                this.router.navigate(['/bookings/create']);
            });
    }

}
