import { Injectable }                   from '@angular/core';
import { HttpClient, HttpParams }       from '@angular/common/http';
import { searchRequest, SearchRequest } from '../models/search.request';
import { Observable }                   from 'rxjs';
import { SearchResponse }               from '../models/search.response';
import { User }                         from './models/user';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private readonly http: HttpClient) {
    }

    search(request: SearchRequest = searchRequest): Observable<SearchResponse<User>> {
        let params = new HttpParams();
        for (const key of Object.keys(request)) {
            params = params.append(key, request[key]);
        }
        return this.http.get('/users/search', {params}) as Observable<SearchResponse<User>>;
    }

    delete(id: number): Observable<User> {
        return this.http.delete('/users/' + id) as Observable<User>;
    }

    getUser(id: number): Observable<User> {
        return this.http.get('/users/' + id) as Observable<User>;
    }

    statistics(id: number): Observable<any[]> {
        return this.http.get('/users/' + id + '/statistics') as Observable<any[]>;
    }

    save(user: User): Observable<User> {
        const params = user.password ? {password: user.password} : {};
        return this.http.post('/users', user, {params}) as Observable<User>;
    }
}
