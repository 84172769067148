import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule }              from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { BrowserModule }                        from '@angular/platform-browser';
import { BrowserAnimationsModule }              from '@angular/platform-browser/animations';
import { NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgStackFormsModule }                   from '@ng-stack/forms';
import { NgxChartsModule }                      from '@swimlane/ngx-charts';

import { AppRoutingModule }        from './app-routing.module';
import { AppComponent }            from './app.component';
import { AutocompleteComponent }   from './booking/create-booking/address.component';
import { CreateBookingComponent }  from './booking/create-booking/create-booking.component';
import { BookingPackageComponent } from './booking/create-booking/booking-package/booking-package.component';
import { BookingContactComponent } from './booking/create-booking/contact/booking-contact.component';
import { InputHasErrorDirective } from './booking/create-booking/utils/input-has-error.directive';
import { ListBookingComponent }   from './booking/list-booking/list-booking.component';
import { BookingComponent }       from './booking/booking.component';
import { ContactComponent }        from './contact/contact.component';
import { CreateContactComponent }  from './contact/create-contact/create-contact.component';
import { ListContactComponent }    from './contact/list-contact/list-contact.component';
import { HorizontalFormComponent } from './forms/horizontal-form/horizontal-form.component';
import { CustomHttpInterceptor }   from './interceptors/custom-http.interceptor';
import { LoginComponent }          from './login/login.component';
import { NavigationComponent }     from './navigation/navigation.component';
import { TableComponent }          from './ui/table/table.component';
import { UserComponent }           from './user/user.component';
import { ListUserComponent }       from './user/list-user/list-user.component';
import { CreateUserComponent }     from './user/create-user/create-user.component';
import { CustomerComponent }       from './customer/customer.component';
import { CreateCustomerComponent } from './customer/create-customer/create-customer.component';
import { ListCustomerComponent }   from './customer/list-customer/list-customer.component';
import { ActionBarComponent }      from './ui/action-bar/action-bar.component';
import { CustomInputComponent }    from './ui/custom-input/custom-input.component';
import { ReportComponent }         from './report/report.component';
import { ListReportComponent }                                 from './report/list-report/list-report.component';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './services/rollbar.service';
import { ImporterComponent } from './importer/importer.component';

@NgModule({
    declarations: [
        InputHasErrorDirective,
        AppComponent,
        LoginComponent,
        BookingComponent,
        CreateBookingComponent,
        ListBookingComponent,
        AutocompleteComponent,
        BookingContactComponent,
        BookingPackageComponent,
        ContactComponent,
        HorizontalFormComponent,
        CreateContactComponent,
        ListContactComponent,
        NavigationComponent,
        TableComponent,
        UserComponent,
        ListUserComponent,
        CreateUserComponent,
        CustomerComponent,
        CreateCustomerComponent,
        ListCustomerComponent,
        ActionBarComponent,
        CustomInputComponent,
        ReportComponent,
        ListReportComponent,
        ImporterComponent
    ],
    imports:      [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgbTypeaheadModule,
        NgbTooltipModule,
        NgStackFormsModule,
        NgxChartsModule
    ],
    providers:    [
        { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory }
    ],
    bootstrap:    [AppComponent],
    schemas:      []
})
export class AppModule {
}
