import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { searchRequest, SearchRequest } from '../models/search.request';
import { SearchResponse } from '../models/search.response';
import { Contact } from '../shipment/models/contact';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(private readonly http: HttpClient) {
    }

    search(request: SearchRequest = searchRequest): Observable<SearchResponse<Contact>> {
        let params = new HttpParams();
        for (const key of Object.keys(request)) {
            params = params.append(key, request[key]);
        }
        return this.http.get('/contacts/search', { params }) as Observable<SearchResponse<Contact>>;
    }

    findById(id: number): Observable<Contact> {
        return this.http.get('/contacts/' + id) as Observable<Contact>;
    }

    save(contact: Contact): Observable<Contact> {
        return this.http.post('/contacts', contact) as Observable<Contact>;
    }

    delete(id: number): Observable<Contact> {
        return this.http.delete('/contacts/' + id) as Observable<Contact>;
    }

    count(ids: number[]): Observable<any[]> {
        let params = new HttpParams();
        for (const id of ids) {
            params = params.append('ids', id.toString());
        }
        return this.http.get('/contacts/count', { params }) as Observable<number[]>;
    }
}
