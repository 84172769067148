<ng-template #headers>
    <div class="col-3">
        Name
    </div>
    <div class="col-3">
        Referenz Nummer
    </div>
    <div class="col-3">
        Adresse
    </div>
    <div class="col-1">
        Tel
    </div>
    <div class="col-2">
        E-Mail
    </div>
</ng-template>

<ng-template #body let-data="result">
    <div class="row align-items-center" *ngFor="let customer of data; let i = index;" routerLink="/customers/{{customer.id}}">
        <div class="col-3">
            {{customer.name}}
        </div>
        <div class="col-3">
            {{customer.reference}}
        </div>
        <div class="col-3">
            <div class="row">
                <div class="col-12 name">
                    {{ customer.street}} {{ customer.streetNumber}}
                </div>
                <div class="col-12">
                    {{ customer.zip}}, {{ customer.city }} / {{ customer.country}}
                </div>
            </div>
        </div>
        <div class="col-1">
            {{ customer.tel || '-' }}        </div>
        <div class="col-2">
            {{ customer.email || '-' }}
        </div>
    </div>
</ng-template>

<app-table (search)="search($event)" [body]="body" [headers]="headers" [size]="size" [onSearchResponse]="onSearchResponse"></app-table>
