import { Injectable }    from '@angular/core';
import { HttpClient }    from '@angular/common/http';
import { Observable }    from 'rxjs';
import { LoginResponse } from '../models/login.response';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private readonly http: HttpClient) {
    }

    login(username: string, password: string): Observable<LoginResponse> {
        const form = new FormData();
        form.append('username', username);
        form.append('password', password);

        return this.http.post('/auth/sign-in', form) as Observable<LoginResponse>;
    }
}
