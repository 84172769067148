import { Component, OnInit }   from '@angular/core';
import { Router }              from '@angular/router';
import { LoginResponse }       from '../login/models/login.response';
import { User }                from '../services/user/models/user';
import { ContextKey }          from '../storage/context.key';
import { LocalStorageContext } from '../storage/local-storage.context';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

    user: User;

    constructor(private readonly router: Router) {
    }

    ngOnInit() {
        this.user = LocalStorageContext.get<LoginResponse>(ContextKey.login)?.user;

        LocalStorageContext.emitter.subscribe(key => {
            if (key === ContextKey.login) {
                this.user = LocalStorageContext.get<LoginResponse>(ContextKey.login)?.user;
            }
        });
    }

    logout() {
        LocalStorageContext.delete(ContextKey.login);
        LocalStorageContext.delete(ContextKey.search);
        LocalStorageContext.delete(ContextKey.page);
        this.user = undefined;
        this.router.navigate(['auth/sign-in']);
    }

    reset() {
        LocalStorageContext.delete(ContextKey.page);
        LocalStorageContext.delete(ContextKey.search);
        return false;
    }
}
