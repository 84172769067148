import { Component, OnInit } from '@angular/core';

@Component({
    selector:    'app-dashboard',
    templateUrl: './booking.component.html',
    styleUrls:   ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
