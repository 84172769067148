import { Component, EventEmitter, Inject, OnInit }     from '@angular/core';
import { searchRequest, SearchRequest, SortDirection } from '../../services/models/search.request';
import { SearchResponse }                              from '../../services/models/search.response';
import { ShipmentService }                             from '../../services/shipment/shipment.service';
import { DomSanitizer, SafeResourceUrl }               from '@angular/platform-browser';
import { Shipment }                                    from '../../services/shipment/models/shipment';
import { PdfGeneratorUtil }                            from './utils/pdf-generator.util';
import { Router }                                      from '@angular/router';
import { DOCUMENT }                                    from '@angular/common';

@Component({
    selector: 'app-booking-list',
    templateUrl: './list-booking.component.html',
    styleUrls: ['./list-booking.component.scss']
})
export class ListBookingComponent implements OnInit {
    size = 25;
    direction: SortDirection = SortDirection.ASC;
    field = 'pid';
    request: SearchRequest;

    onSearchResponse: EventEmitter<SearchResponse<any>> = new EventEmitter<SearchResponse<any>>();

    page: SafeResourceUrl;

    constructor(private readonly shipmentService: ShipmentService,
                private readonly router: Router,
                @Inject(DOCUMENT) private readonly dom: Document) {
    }

    async ngOnInit() {
    }

    search(request: SearchRequest) {
        this.request = request;
        this.load();
    }

    async createPdf(shipment: Shipment, $event: Event) {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        await PdfGeneratorUtil.generate(shipment);
    }

    sort(field: string) {
        this.direction = this.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        this.field = field;
        this.load()
    }

    activate(shipment: Shipment) {
        if (this.dom.getSelection()?.toString()) {
            return;
        }
        this.router.navigate([ '/bookings/' + shipment.id ]);
    }

    private load() {
        this.request = this.request || searchRequest;
        this.request.size = this.size;
        this.request.field = this.field;
        this.request.sort = this.direction;

        this.shipmentService.search(this.request).subscribe(response => {
            this.onSearchResponse.emit(response);
        });
    }
}
